@import "../../sass/variables";

.simulator-section {
  width: 100%;
  display: flex;
  padding-top: 4rem;
  .hand-deco {
    position: fixed;
    bottom: 0;
    left: 20%;
  }
  .simulator-subcontainer {
    width: 1024px;
    display: grid;
    justify-content: space-between;
    align-items: center;
    margin: 5rem 1rem 0 27%;
    row-gap: 4rem;
    .pmi-elm-icon {
      width: 3rem;
    }
    .sim-calculator-container {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 1rem;
    }
    .sim-calculator-elm-container {
      background-color: $mainColorPrimary;
      border-radius: 0.5rem;
      padding: 2rem;
      display: flex;
      flex-direction: column;
      row-gap: 1rem;
      align-items: center;
      box-shadow: 10px 10px 19px -4px rgba(0, 0, 0, 0.25);
      -webkit-box-shadow: 10px 10px 19px -4px rgba(0, 0, 0, 0.25);
      -moz-box-shadow: 10px 10px 19px -4px rgba(0, 0, 0, 0.25);
    }
    .sce-title {
      font-size: 1.1rem;
      font-weight: 300;
      text-align: center;
      color: $basicColorPrimary;
      width: 100%;
    }
    .sce-inputs-container {
      color: $basicColorPrimary;
      display: flex;
      flex-direction: column;
      row-gap: 1rem;
    }
    .sce-grid-inputs {
      display: grid;
      grid-template-columns: 50% 50%;
      align-items: flex-end;
      column-gap: 1rem;
    }
    .label-subtitle {
      font-size: 1rem;
      font-weight: 200;
    }
    .style-label {
      display: flex;
      flex-direction: column;
      row-gap: 0.5rem;
    }
    .radio-btns-container {
      display: flex;
      flex-direction: column;
      row-gap: 0.5rem;
    }
    .radio-opt-container {
      display: flex;
      justify-content: space-between;
    }
    .radio-label {
      display: flex;
      column-gap: 1rem;
      align-items: center;
    }
    .payment-info-container {
      display: flex;
      flex-direction: column;
      row-gap: 1rem;
    }
    .pi-title {
      color: $basicColorPrimary;
      font-size: 1.1rem;
      font-weight: 300;
    }
    .payment-methods-info-container {
      display: grid;
      grid-template-columns: 40% 60%;
      column-gap: 0.5rem 0 0.5rem 1rem;
    }
    .advise-container {
      font-size: 0.7rem;
      color: $basicColorPrimary;
      margin-top: 1rem;
    }
    .pmi-elm-container {
      display: flex;
      align-items: center;
      column-gap: 1rem;
      font-size: 0.8rem;
      color: $basicColorPrimary;
    }
    .bordered {
      border-left: 1px solid $basicColorPrimary;
      padding-left: 0.5rem;
    }
    .input-txt {
      padding: 0.5rem 0 0.5rem 2rem;
      background-color: $mainColorPrimary;
      border: 1px solid $basicColorPrimary;
      outline: none;
      border-radius: 1rem;
      color: $basicColorPrimary;
    }
    .input-txt::placeholder {
      color: $basicColorPrimary;
    }
    .date {
      position: relative;
      padding-right: 0.9rem;
    }
    .date::after {
      content: "\1F4C6";
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-55%);
      font-size: 1.1rem;
      pointer-events: none;
      cursor: pointer;
    }
    .date-info {
      top: 10rem !important;
    }
    .init-premium-info {
      top: 16rem !important;
    }
    .date-label-container {
       display: flex;
       align-items: center;
       column-gap: 0.5rem;
    }
    .input-select {
      padding: 0.5rem;
      background-color: $mainColorPrimary;
      border: 1px solid $basicColorPrimary;
      border-radius: 1rem;
      color: $basicColorPrimary;
      outline: none;
    }
    .input-radio {
      cursor: pointer;
    }
    .label-subtitle-with-info {
      display: flex;
      column-gap: 0.5rem;
    }
    .info-subtitle-container {
      position: absolute;
      width: 25rem;
      top: 20rem;
    }
    .subtitle-info-icon {
      width: 1rem;
      height: 1rem;
      cursor: pointer;
    }
    .sim-calculator-result-container {
      border: 1px solid $mainColorPrimary;
      border-radius: 0.5rem;
      padding: 2rem 1rem;
      display: flex;
      flex-direction: column;
      row-gap: 3rem;
      align-items: center;
      box-shadow: 10px 10px 19px -4px rgba(0, 0, 0, 0.25);
      -webkit-box-shadow: 10px 10px 19px -4px rgba(0, 0, 0, 0.25);
      -moz-box-shadow: 10px 10px 19px -4px rgba(0, 0, 0, 0.25);
    }
    .scr-title {
      font-size: 1rem;
      color: $basicColorPrimary;
      border: 1px solid $mainColorPrimary;
      background-color: $mainColorPrimary;
      padding: 0.25rem 1rem;
      text-align: center;
      border-radius: 1rem;
      font-weight: 300;
    }
    .scr-info-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      row-gap: 1rem;
      color: $mainColorPrimary;
    }
    .src-info-subcontainer {
      display: flex;
      justify-content: space-between;
      padding: 0 1rem;
    }
    .deco-field {
      background-color: $basicColorPrimary;
      padding: 0.5rem 1rem;
      border-radius: 0.5rem;
    }
    .advise {
      font-size: 0.8rem;
    }
    .generate-file-btn-container {
      width: 100%;
      display: flex;
      justify-content: center;
      column-gap: 1rem;
      color: transparent;
    }
    .print-btn {
      padding: 0.5rem;
      width: 100%;
      background-color: $scrollBarColorPrimary;
      border: 1px solid $scrollBarColorPrimary;
      color: $mainColorPrimary;
      font-weight: bold;
      border-radius: 0.5rem;
      cursor: pointer;
      &:hover {
        background-color: $mainColorPrimary;
        border-color: $mainColorPrimary;
        color: $scrollBarColorPrimary;
      }
    }
  }
}

//Responsive Mobile Small
@media screen and (min-width: $mobileSm) and (max-width: calc($mobileStandard - 1px)) {
  .hand-deco {
    display: none;
  }
  .simulator-subcontainer {
    margin: 7rem 0 5rem 0 !important;
    .sim-calculator-container {
      grid-template-columns: 1fr !important;
      row-gap: 2rem;
      .pmi-elm-container {
        font-size: 0.5rem;
      }
      .sce-grid-inputs {
        grid-template-columns: 1fr;
      }
      .input-select {
        width: 100%;
      }
      .radio-opt-container {
        column-gap: 1rem;
      }
      .payment-methods-info-container {
        grid-template-columns: 50% 50%;
      }
    }
  }
}

//Responsive Mobile Standard
@media screen and (min-width: $mobileStandard) and (max-width: calc($tabletSm - 1px)) {
  .hand-deco {
    display: none;
  }
  .simulator-subcontainer {
    margin: 7rem 0 5rem 0 !important;
    justify-content: center !important;
    .sim-calculator-container {
      grid-template-columns: 1fr !important;
      row-gap: 2rem;
      margin: 0 10%;
      .pmi-elm-container {
        font-size: 0.5rem;
      }
      .sce-grid-inputs {
        grid-template-columns: 1fr;
      }
      .input-select {
        width: 100%;
      }
      .radio-opt-container {
        column-gap: 1rem;
      }
      .payment-methods-info-container {
        grid-template-columns: 50% 50%;
      }
    }
  }
}

//Responsive Tablet Small
@media screen and (min-width: $tabletSm) and (max-width: calc($tabletStandard - 1px)) {
  .hand-deco {
    display: none;
  }
  .simulator-subcontainer {
    margin: 12rem 0 5rem 0 !important;
    justify-content: center !important;
    .sim-calculator-container {
      margin: 0 5%;
      .pmi-elm-container {
        font-size: 0.5rem;
      }
      .sce-grid-inputs {
        grid-template-columns: 1fr;
      }
      .input-select {
        width: 100%;
      }
      .radio-opt-container {
        column-gap: 1rem;
      }
      .payment-methods-info-container {
        grid-template-columns: 50% 50%;
      }
    }
  }
}

//Responsive Small Screen
@media screen and (min-width: $screenSm) and (max-width: calc($screenHd - 1px)) {
  .hand-deco {
    bottom: -7rem !important;
  }
  .simulator-subcontainer {
    margin-left: 15% !important;
    margin-bottom: 5rem !important;
  }
}

//Responsive HD Screen
@media screen and (min-width: $screenHd) {
  .simulator-section {
    justify-content: center;
    .hand-deco {
      left: 10% !important;
    }
    .simulator-subcontainer {
      margin-left: 0 !important;
      margin-bottom: 5rem !important;
    }
  }
}
