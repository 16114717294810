@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@1,700&display=swap");

/*Colors*/

$basicColorPrimary: #ffffff;
$basicColorSecondary: #000000;
$mainColorPrimary: #014da1;
$barGraphListColorPrimary: #868686;
$scrollBarColorPrimary: #42ffff;
$nbBackgroundColor: #f6f6f6;
$alertBtnColorPrimary: #ff0000;

/*Colors Transparency*/

$basicColorPrimaryOpa: #ffffffda;

/*Hover Colors*/

/*Button Color Effect*/

/*Styles*/

* {
  margin: 0;
}

/*Responsive - breakpoints*/
$mobileSm: 320px;
$mobileStandard: 480px;
$tabletSm: 768px;
$tabletStandard: 1024px;
$screenSm: 1200px;
$screenHd: 1920px;