@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@1,700&display=swap");
/*Colors*/
/*Colors Transparency*/
/*Hover Colors*/
/*Button Color Effect*/
/*Styles*/
* {
  margin: 0;
}

/*Responsive - breakpoints*/
.notification-container-mobile {
  position: fixed;
  width: 19.5rem;
  height: 16.8rem;
  background-color: #ffffff;
  overflow-y: scroll;
  z-index: 99;
  bottom: -100%;
  left: 0rem;
  overflow-x: hidden;
  border-radius: 1rem 0 0 0;
  border-top: 1px solid #014da1;
  border-right: 1px solid #014da1;
  border-left: 1px solid #014da1;
}
.notification-container-mobile::-webkit-scrollbar {
  width: 0.5rem;
  background-color: #ffffff;
  border-radius: 1rem;
}
.notification-container-mobile::-webkit-scrollbar-thumb {
  width: 0.5rem;
  background-color: #ffffff;
  border-radius: 1rem;
}

.show-noti-mb {
  animation-name: showNotiMobile;
  animation-duration: 1s;
  bottom: 3rem;
}
@keyframes showNotiMobile {
  0% {
    bottom: -100%;
  }
  100% {
    bottom: 3rem;
  }
}

.hide-noti-mb {
  animation-name: hideNotiMobile;
  animation-duration: 2s;
  bottom: -100%;
}
@keyframes hideNotiMobile {
  0% {
    bottom: 3rem;
  }
  100% {
    bottom: -100%;
  }
}

.navbar-mobile-bottom-container {
  background-color: #f6f6f6;
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 3rem;
  z-index: 99;
}
.navbar-mobile-bottom-container .nb-menus-container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  -moz-column-gap: 1rem;
       column-gap: 1rem;
  height: 100%;
}
.navbar-mobile-bottom-container .profile-text-container {
  color: #868686;
}
.navbar-mobile-bottom-container .menu-container {
  display: flex;
  align-items: center;
  -moz-column-gap: 0.5rem;
       column-gap: 0.5rem;
  cursor: pointer;
}
.navbar-mobile-bottom-container .line {
  border-left: 1px solid #014da1;
  padding-left: 0.5rem;
}
.navbar-mobile-bottom-container .menu-icon {
  width: 1.2rem;
}
.navbar-mobile-bottom-container h1 {
  font-size: 0.8rem;
}

@media screen and (min-width: 320px) and (max-width: 1023px) {
  .notification-container-mobile {
    width: 24rem !important;
  }
}
@media screen and (min-width: 1024px) {
  .notification-container-mobile {
    display: none;
  }
  .navbar-mobile-bottom-container {
    display: none !important;
  }
}/*# sourceMappingURL=NavbarMobileBottom.css.map */