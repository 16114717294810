@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@1,700&display=swap");
/*Colors*/
/*Colors Transparency*/
/*Hover Colors*/
/*Button Color Effect*/
/*Styles*/
* {
  margin: 0;
}

/*Responsive - breakpoints*/
.loading-container {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  background-color: rgba(255, 255, 255, 0.8549019608);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  row-gap: 1rem;
}
.loading-container .loading-logo {
  width: 15rem;
}
.loading-container .overlay {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  -moz-column-gap: 1rem;
       column-gap: 1rem;
}
.loading-container .bar {
  height: 10px;
  width: 10px;
  border: 3px solid #014da1;
  content: "";
  top: 10%;
  border-radius: 100%;
  animation: ball 2s infinite linear;
}
.loading-container .bar:nth-child(2n) {
  animation-delay: 0.2s;
}
.loading-container .bar:nth-child(3n) {
  animation-delay: 0.4s;
}
@keyframes ball {
  0% {
    transform: scale(0.5);
    background: #014da1;
  }
  50% {
    transform: scale(1);
    background: transparent;
  }
  100% {
    transform: scale(0.5);
    background: #014da1;
  }
}
.loading-container .loading-complement-txt {
  font-size: 0.7rem;
  color: #014da1;
  width: 15rem;
}

.has-txt {
  flex-direction: column;
  row-gap: 1rem;
}/*# sourceMappingURL=LoadingScreenComponent.css.map */