@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@1,700&display=swap");
/*Colors*/
/*Colors Transparency*/
/*Hover Colors*/
/*Button Color Effect*/
/*Styles*/
* {
  margin: 0;
}

/*Responsive - breakpoints*/
.list-container {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  row-gap: 0.5rem;
}
.list-container .list-title {
  font-size: 1.3rem;
  color: #868686;
  font-weight: 500;
  margin-bottom: 1rem;
}
.list-container .list-header {
  display: flex;
  align-items: center;
  font-size: 0.65rem;
  -moz-column-gap: 1rem;
       column-gap: 1rem;
  color: #014da1;
  border-bottom: 1px solid #014da1;
  padding-bottom: 0.4rem;
  justify-content: space-around;
}
.list-container .list-header h1 {
  font-weight: 500;
}
.list-container .list-info-container {
  font-size: 0.8rem;
  color: #014da1;
}
.list-container .list-other-description-container {
  font-size: 0.8rem;
  color: #014da1;
}
.list-container .list-subcontainer {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
}
.list-container scroll-container {
  display: block;
  height: 200px;
  overflow-y: scroll;
  scroll-behavior: smooth;
}
.list-container scroll-container::-webkit-scrollbar {
  width: 0.5rem;
  background-color: #868686;
  border-radius: 1rem;
}
.list-container scroll-container::-webkit-scrollbar-thumb {
  width: 0.5rem;
  background-color: #42ffff;
  border-radius: 1rem;
}
.list-container scroll-page {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
  font-size: 1em;
}
.list-container scroll-page .scroll-list-data-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
}
.list-container scroll-page .scroll-data-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #dddddd;
  background-color: #dddddd;
  width: 90%;
  border-radius: 1rem;
  padding-left: 1rem;
}
.list-container scroll-page .values-subcontainer {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #014da1;
  margin-left: 2.5rem;
  padding: 0.5rem 2rem;
  color: #ffffff;
  border-radius: 1rem;
  min-width: 2.5rem;
}
.list-container scroll-page .txt-data {
  font-size: 0.8rem;
  width: 10rem;
}
.list-container scroll-page .num-credits {
  border: 1px solid #014da1;
  background-color: #014da1;
  border-radius: 1rem;
  width: 40%;
  padding: 0.4rem 0 0.4rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.list-container scroll-page .sd-img {
  width: auto;
  max-height: 1.5rem;
}

@media screen and (min-width: 320px) and (max-width: 479px) {
  .list-container {
    align-items: unset;
  }
}
@media screen and (min-width: 480px) and (max-width: 767px) {
  .list-container {
    align-items: unset;
    margin: 0 10%;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .list-container {
    align-items: unset;
    margin: 0 10%;
  }
}
@media screen and (min-width: 1200px) and (max-width: 1919px) {
  .list-subcontainer {
    width: 95%;
  }
}
@media screen and (min-width: 1920px) {
  .list-subcontainer {
    width: 100%;
  }
}/*# sourceMappingURL=ListComponent.css.map */