@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@1,700&display=swap");
/*Colors*/
/*Colors Transparency*/
/*Hover Colors*/
/*Button Color Effect*/
/*Styles*/
* {
  margin: 0;
}

/*Responsive - breakpoints*/
.cancellation-section {
  width: 100%;
  display: flex;
  padding-top: 4rem;
}
.cancellation-section .cancellation-subcontainer {
  width: 1024px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 5rem 1rem 0 27%;
  row-gap: 4rem;
}
.cancellation-section .cancellation-subcontainer .c-title {
  font-size: 1.8rem;
  font-weight: 500;
  color: #014da1;
}
.cancellation-section .cancellation-subcontainer .cdt-txt {
  color: #014da1;
}
.cancellation-section .cancellation-subcontainer .c-description-txt-container {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
}
.cancellation-section .cancellation-subcontainer .input-container {
  display: flex;
  -moz-column-gap: 1rem;
       column-gap: 1rem;
  align-items: flex-end;
}
.cancellation-section .cancellation-subcontainer .cip-de-btn {
  padding: 0.55rem 1rem;
  background-color: #014da1;
  color: #ffffff;
  font-weight: bold;
  border: 1px solid #014da1;
  cursor: pointer;
  border-radius: 0.5rem;
  height: 100%;
  width: 12rem;
}
.cancellation-section .cancellation-subcontainer .cip-de-btn:hover {
  background-color: #42ffff;
  border-color: #42ffff;
  color: #014da1;
}
.cancellation-section .cancellation-subcontainer .filter-form-container {
  display: grid;
  align-items: flex-end;
  -moz-column-gap: 1rem;
       column-gap: 1rem;
  grid-template-columns: 1fr 1fr 1fr;
}
.cancellation-section .cancellation-subcontainer label {
  display: flex;
  flex-direction: column;
  font-size: 0.9rem;
  row-gap: 0.5rem;
}
.cancellation-section .cancellation-subcontainer .label-title {
  font-size: 0.9rem;
  color: #014da1;
  font-weight: 500;
}
.cancellation-section .cancellation-subcontainer input {
  padding: 0.5rem 1rem;
  outline: none;
  border: 1px solid #868686;
  border-radius: 0.5rem;
  cursor: pointer;
  color: #868686;
  font-weight: 500;
}
.cancellation-section .cancellation-subcontainer select {
  padding: 0.5rem 1rem;
  outline: none;
  border: 1px solid #868686;
  color: #868686;
  border-radius: 0.5rem;
  cursor: pointer;
  font-weight: 500;
}
.cancellation-section .cancellation-subcontainer .c-input-search {
  padding: 0.5rem 1rem;
  border: 1px solid #014da1;
  color: #014da1;
  background-color: #ffffff;
  outline: none;
  border-radius: 0.5rem;
  width: 50%;
}
.cancellation-section .cancellation-subcontainer .c-input-search::-moz-placeholder {
  color: #014da1;
}
.cancellation-section .cancellation-subcontainer .c-input-search::placeholder {
  color: #014da1;
}
.cancellation-section .cancellation-subcontainer .c-btn-search {
  padding: 0.55rem 1rem;
  background-color: #014da1;
  color: #ffffff;
  font-weight: bold;
  border: 1px solid #014da1;
  cursor: pointer;
  border-radius: 0.5rem;
  height: 100%;
  width: 12rem;
}
.cancellation-section .cancellation-subcontainer .c-btn-search:hover {
  background-color: #42ffff;
  border-color: #42ffff;
  color: #014da1;
}

@media screen and (min-width: 320px) and (max-width: 479px) {
  .cancellation-subcontainer {
    margin: 7rem 0 4rem 0 !important;
  }
  .cancellation-subcontainer .filter-elms-container {
    margin: 0 1rem;
  }
  .cancellation-subcontainer .filter-elms-container .filter-form-container {
    grid-template-columns: 1fr !important;
    width: 100% !important;
  }
  .cancellation-subcontainer select {
    width: 90vw !important;
  }
  .cancellation-subcontainer .input-container {
    flex-direction: column;
    row-gap: 1rem;
    align-items: center !important;
  }
  .cancellation-subcontainer .docs-container {
    flex-direction: column;
  }
  .cancellation-subcontainer .profile-deco {
    display: none;
  }
  .cancellation-subcontainer .cd-logo {
    width: 7rem !important;
  }
  .cancellation-subcontainer .credit-details-table-supercontainer {
    width: 90vw;
    overflow-x: scroll;
    margin-left: 1rem;
  }
}
@media screen and (min-width: 480px) and (max-width: 767px) {
  .cancellation-subcontainer {
    margin: 7rem 0 4rem 0 !important;
    align-items: center;
  }
  .cancellation-subcontainer .filter-elms-container {
    margin: 0 1rem;
  }
  .cancellation-subcontainer .filter-elms-container .filter-form-container {
    grid-template-columns: 50% 50% !important;
    width: 90vw;
  }
  .cancellation-subcontainer .input-container {
    flex-direction: column;
    row-gap: 1rem;
    align-items: center !important;
  }
  .cancellation-subcontainer .c-elms-container {
    margin: 0 10%;
  }
  .cancellation-subcontainer .docs-container {
    flex-direction: column;
  }
  .cancellation-subcontainer .profile-deco {
    display: none;
  }
  .cancellation-subcontainer .cd-logo {
    width: 7rem !important;
  }
  .cancellation-subcontainer .credit-details-table-supercontainer {
    width: 90vw;
    overflow-x: scroll;
    margin-left: 1rem;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .cancellation-subcontainer {
    margin: 12rem 0 4rem 0 !important;
    align-items: center;
  }
  .cancellation-subcontainer .filter-elms-container {
    margin: 0 1rem;
  }
  .cancellation-subcontainer .filter-elms-container .filter-form-container {
    grid-template-columns: 1fr 1fr !important;
  }
  .cancellation-subcontainer .input-container {
    flex-direction: column;
    row-gap: 1rem;
    align-items: center !important;
  }
  .cancellation-subcontainer .credit-details-table-supercontainer {
    width: 90vw;
    overflow-x: scroll;
    margin-left: 1rem;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1199px) {
  .filter-elms-container {
    margin: 0 1rem;
  }
  .filter-elms-container .filter-form-container {
    grid-template-columns: 1fr 1fr !important;
  }
  .input-container {
    flex-direction: column;
    row-gap: 1rem;
    align-items: center !important;
  }
  .credit-details-table-supercontainer {
    width: 70vw;
    overflow-x: scroll;
    margin-left: 1rem;
  }
}
@media screen and (min-width: 1200px) and (max-width: 1919px) {
  .cancellation-subcontainer {
    margin-left: 15% !important;
    margin-bottom: 5rem !important;
  }
  .cancellation-subcontainer .filter-elms-container {
    margin: 0 1rem;
  }
  .cancellation-subcontainer .filter-elms-container .filter-form-container {
    grid-template-columns: 1fr 1fr !important;
  }
  .cancellation-subcontainer .input-container {
    flex-direction: column;
    row-gap: 1rem;
    align-items: center !important;
  }
  .cancellation-subcontainer .credit-details-table-supercontainer {
    width: 70vw;
    overflow-x: scroll;
    margin-left: 1rem;
  }
}
@media screen and (min-width: 1920px) {
  .cancellation-section {
    justify-content: center;
  }
  .cancellation-section .cancellation-subcontainer {
    margin-left: 0 !important;
    margin-bottom: 5rem !important;
  }
  .cancellation-section .profile-deco {
    right: -15%;
  }
}/*# sourceMappingURL=CancellationBody.css.map */