@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@1,700&display=swap");
/*Colors*/
/*Colors Transparency*/
/*Hover Colors*/
/*Button Color Effect*/
/*Styles*/
* {
  margin: 0;
}

/*Responsive - breakpoints*/
.profile-form-container {
  width: 20rem;
  border: 1px solid #014da1;
  border-radius: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3rem 2rem;
  box-shadow: 10px 10px 25px -8px rgba(0, 0, 0, 0.4);
  -webkit-box-shadow: 10px 10px 25px -8px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 10px 10px 25px -8px rgba(0, 0, 0, 0.4);
  background-color: #ffffff;
}
.profile-form-container .pf-subcontainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 2rem;
}
.profile-form-container .pf-subcontainer .pf-logo {
  width: 13rem;
}
.profile-form-container .pf-subcontainer .pf-inputs-container {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  width: 100%;
  align-items: center;
}
.profile-form-container .pf-subcontainer .label {
  width: 100%;
  background-color: #ffffff;
  border: 1px solid #dddddd;
  border-radius: 0.2rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.profile-form-container .pf-subcontainer .border-err {
  border-color: #ff0000;
}
.profile-form-container .pf-subcontainer .password-advice {
  color: #014da1;
  font-size: 0.8rem;
  display: none;
}
.profile-form-container .pf-subcontainer .pa-show {
  display: unset;
}
.profile-form-container .pf-subcontainer .input-icon {
  width: 1rem;
  height: auto;
  margin-left: 1rem;
}
.profile-form-container .pf-subcontainer .phone-fix {
  height: 1.1rem;
}
.profile-form-container .pf-subcontainer .edit-icon {
  width: 1rem;
  height: 1rem;
  margin-right: 0.5rem;
  cursor: pointer;
}
.profile-form-container .pf-subcontainer input {
  width: 80%;
  padding: 0.5rem 0 0.5rem 1rem;
  outline: none;
  background-color: #ffffff;
  border: 1px solid #ffffff;
}
.profile-form-container .pf-subcontainer .pf-btn-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  -moz-column-gap: 2rem;
       column-gap: 2rem;
  margin-top: 1rem;
}
.profile-form-container .pf-subcontainer .pf-btn {
  width: 100%;
  padding: 0.5rem;
  background-color: #42ffff;
  color: #014da1;
  border: 1px solid #42ffff;
  border-radius: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  cursor: pointer;
}
.profile-form-container .pf-subcontainer .pf-btn:hover {
  background-color: #014da1;
  border-color: #014da1;
  color: #ffffff;
}
.profile-form-container .pf-subcontainer .cancel {
  background-color: #ff0000;
  border-color: #ff0000;
  color: #ffffff;
}
.profile-form-container .pf-subcontainer .cancel:hover {
  background-color: #ffffff;
  border-color: #ff0000;
  color: #ff0000;
}

@media screen and (min-width: 320px) and (max-width: 479px) {
  .profile-form-container {
    width: 79% !important;
  }
  .profile-form-container input {
    width: 100% !important;
  }
}/*# sourceMappingURL=ProfileFormComponent.css.map */