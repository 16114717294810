@import '../../sass/variables';

.attach-example-container {
    width: 100%;
    height: 100%;
    background-color: $basicColorPrimary;
    border: 1px solid $mainColorPrimary;
    border-radius: 1rem;
    box-shadow: 10px 6px 18px -8px rgba(0, 0, 0, 0.67);
    .attach-example-subcontainer {
        margin: 2rem;
        display: flex;
        flex-direction: column;
        row-gap: 2rem;
        .ae-txt-container {
            display: flex;
            align-items: flex-start;
            justify-content: center;
            column-gap: 1rem;
        }
        .advice-txt-icon {
            width: 1.5rem;
            height: 100%;
            margin-top: 0.5rem;
        }
        .advice-txt {
            font-size: 1rem;
            font-weight: 400;
            color: $mainColorPrimary;

        }
        .advice-img {
            width: 100%;
            height: 100%;
        }
    }
}