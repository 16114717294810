@import "../../sass/variables";

.loading-container {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  background-color: $basicColorPrimaryOpa;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  row-gap: 1rem;
  .loading-logo {
    width: 15rem;
  }

  .overlay {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 1rem;
  }

  .bar {
    height: 10px;
    width: 10px;
    border: 3px solid $mainColorPrimary;
    content: "";
    top: 10%;
    border-radius: 100%;
    animation: ball 2s infinite linear;
  }

  .bar:nth-child(2n) {
    animation-delay: 0.2s;
  }
  .bar:nth-child(3n) {
    animation-delay: 0.4s;
  }

  @keyframes ball {
    0% {
      transform: scale(0.5);
      background: $mainColorPrimary;
    }
    50% {
      transform: scale(1);
      background: transparent;
    }
    100% {
      transform: scale(0.5);
      background: $mainColorPrimary;
    }
  }
  .loading-complement-txt {
    font-size: 0.7rem;
    color: $mainColorPrimary;
    width: 15rem;
  }
}

.has-txt {
  flex-direction: column;
  row-gap: 1rem;
}
