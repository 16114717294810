@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@1,700&display=swap");
/*Colors*/
/*Colors Transparency*/
/*Hover Colors*/
/*Button Color Effect*/
/*Styles*/
* {
  margin: 0;
}

/*Responsive - breakpoints*/
.pie-graph-container {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
}
.pie-graph-container .total-container {
  font-size: 1.1rem;
  color: #014da1;
  text-align: center;
}

.pg-title {
  font-size: 1.3rem;
  color: #868686;
  font-weight: 500;
  margin-bottom: 1rem;
}

.pg-info-container {
  font-size: 0.8rem;
  color: #014da1;
}

.subtitle-info-icon {
  width: 1rem;
  height: 1rem;
  cursor: pointer;
}

.bg-info-container {
  font-size: 0.8rem;
  color: #014da1;
}

.info-subtitle-container {
  position: absolute;
  width: 25rem;
  top: 9rem;
}

.pg-title-container {
  display: flex;
  align-items: baseline;
  -moz-column-gap: 0.5rem;
       column-gap: 0.5rem;
}

.pg-elms-container {
  display: grid;
  grid-template-columns: 70% 30%;
  -moz-column-gap: 1rem;
       column-gap: 1rem;
  justify-content: flex-end;
  align-items: center;
  row-gap: 0.5rem;
}
.pg-elms-container .pg-total-container {
  width: 100%;
  text-align: center;
}
.pg-elms-container .pg-total-container .pg-total-num {
  font-size: 1rem;
  color: #014da1;
}
.pg-elms-container .pg-elm-info {
  font-size: 0.8rem;
  color: #014da1;
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
}
.pg-elms-container .pg-elm-container {
  position: relative;
}
.pg-elms-container .pg-elm-btns-container {
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
}
.pg-elms-container .pg-elm-btns-container .pg-btns {
  background-color: #ffffff;
  border: 1px solid #ffffff;
  color: #014da1;
  border-radius: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

@media screen and (min-width: 320px) and (max-width: 479px) {
  .pg-elms-container {
    grid-template-columns: 1fr !important;
    row-gap: 2rem;
    width: 100% !important;
  }
  .info-subtitle-container {
    width: 100vw;
    top: 13rem;
  }
}
@media screen and (min-width: 480px) and (max-width: 767px) {
  .pg-elms-container {
    grid-template-columns: 1fr !important;
    row-gap: 2rem;
    justify-content: center !important;
    width: unset !important;
  }
  .pg-total-container {
    font-size: 0.7rem;
  }
  .pie-graph-container {
    margin: 0 10%;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .pg-elms-container {
    grid-template-columns: 1fr !important;
    row-gap: 2rem;
    justify-content: center !important;
    width: unset !important;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1199px) {
  .pg-total-container {
    font-size: 0.7rem;
  }
}
@media screen and (min-width: 1200px) and (max-width: 1919px) {
  .pg-total-container {
    font-size: 0.7rem;
  }
}/*# sourceMappingURL=PieGraphComponent.css.map */