@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@1,700&display=swap");
/*Colors*/
/*Colors Transparency*/
/*Hover Colors*/
/*Button Color Effect*/
/*Styles*/
* {
  margin: 0;
}

/*Responsive - breakpoints*/
.login-screen-container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  overflow: hidden;
  background-color: #014da1;
}
.login-screen-container .login-screen-bg-img {
  height: auto;
  width: 1920px;
  -o-object-fit: cover;
     object-fit: cover;
}
.login-screen-container .login-screen-deco-img {
  position: absolute;
  right: 0;
  bottom: 0;
  height: 95vh;
  width: auto;
}
.login-screen-container .login-screen-subcontainer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  row-gap: 1rem;
  position: absolute;
}
.login-screen-container .login-screen-subcontainer .login-txt-container {
  text-align: center;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
}
.login-screen-container .login-screen-subcontainer .login-form-supercontainer {
  width: 30rem;
}
.login-screen-container .login-screen-subcontainer .login-logo {
  width: 20rem;
  height: auto;
}

@media screen and (min-width: 320px) and (max-width: 479px) {
  .login-screen-deco-img {
    display: none !important;
  }
  .login-logo {
    width: 12rem !important;
  }
  .login-form-supercontainer {
    width: 90% !important;
  }
}
@media screen and (min-width: 480px) and (max-width: 767px) {
  .login-screen-deco-img {
    display: none !important;
  }
  .login-logo {
    width: 20rem !important;
  }
  .login-form-supercontainer {
    width: 90% !important;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .login-screen-deco-img {
    display: none !important;
  }
  .login-logo {
    width: 20rem !important;
  }
  .login-form-supercontainer {
    width: 60% !important;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1199px) {
  .login-screen-deco-img {
    display: none !important;
  }
}/*# sourceMappingURL=LoginScreen.css.map */