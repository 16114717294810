@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@1,700&display=swap");
/*Colors*/
/*Colors Transparency*/
/*Hover Colors*/
/*Button Color Effect*/
/*Styles*/
* {
  margin: 0;
}

/*Responsive - breakpoints*/
.notification-container {
  position: absolute;
  width: 23rem;
  height: 16.8rem;
  background-color: #ffffff;
  overflow-y: scroll;
  z-index: 99;
  top: -100vh;
  right: 14rem;
  overflow-x: hidden;
  border-radius: 0 0 0 1rem;
}
.notification-container::-webkit-scrollbar {
  width: 0.5rem;
  background-color: #ffffff;
  border-radius: 1rem;
}
.notification-container::-webkit-scrollbar-thumb {
  width: 0.5rem;
  background-color: #ffffff;
  border-radius: 1rem;
}

.show-noti {
  animation-name: showNoti;
  animation-duration: 1s;
  top: 5rem;
}
@keyframes showNoti {
  0% {
    top: -100vh;
  }
  100% {
    top: 5rem;
  }
}

.hide-noti {
  animation-name: hideNoti;
  animation-duration: 2s;
  top: -100vh;
}
@keyframes hideNoti {
  0% {
    top: 5rem;
  }
  100% {
    top: -100vh;
  }
}

.navbar-container {
  width: 100%;
  height: 4rem;
  background-color: #f6f6f6;
  position: fixed;
  display: flex;
  justify-content: flex-end;
  z-index: 99;
}
.navbar-container .elms-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 70%;
  -moz-column-gap: 1rem;
       column-gap: 1rem;
}
.navbar-container .elms-container .nb-superadmin-menu {
  display: grid;
  grid-template-columns: 60% 40%;
  -moz-column-gap: 1rem;
       column-gap: 1rem;
  min-width: 20rem;
}
.navbar-container .elms-container .nb-sa-select {
  border: 1px solid #868686;
  border-radius: 0.25rem;
  color: #868686;
  outline: none;
}
.navbar-container .elms-container .nb-sa-btn {
  background-color: #014da1;
  border: 1px solid #014da1;
  color: #ffffff;
  padding: 0.25rem;
  cursor: pointer;
  border-radius: 0.25rem;
}
.navbar-container .elms-container .nb-sa-btn:hover {
  background-color: #ffffff;
  color: #014da1;
}
.navbar-container .elms-container .nb-menus-container {
  display: flex;
  align-items: center;
  -moz-column-gap: 1rem;
       column-gap: 1rem;
  margin-right: 5%;
}
.navbar-container .elms-container .profile-text-container {
  color: #868686;
}
.navbar-container .elms-container .menu-container {
  display: flex;
  align-items: center;
  -moz-column-gap: 0.5rem;
       column-gap: 0.5rem;
  cursor: pointer;
}
.navbar-container .elms-container .line {
  border-left: 1px solid #014da1;
  padding-left: 0.5rem;
}
.navbar-container .elms-container .menu-icon {
  height: 1.5rem;
  width: auto;
}
.navbar-container .elms-container h1 {
  font-size: 0.8rem;
}

@media screen and (min-width: 320px) and (max-width: 479px) {
  .notification-container {
    display: none;
  }
  .navbar-container {
    display: none;
  }
}
@media screen and (min-width: 480px) and (max-width: 767px) {
  .notification-container {
    display: none;
  }
  .navbar-container {
    display: none;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .notification-container {
    display: none;
  }
  .navbar-container {
    display: none;
  }
}
@media screen and (min-width: 1200px) and (max-width: 1919px) {
  .navbar-container {
    justify-content: space-around;
  }
}
@media screen and (min-width: 1920px) {
  .navbar-container {
    justify-content: center;
  }
}/*# sourceMappingURL=NavbarComponent.css.map */