@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@1,700&display=swap");
/*Colors*/
/*Colors Transparency*/
/*Hover Colors*/
/*Button Color Effect*/
/*Styles*/
* {
  margin: 0;
}

/*Responsive - breakpoints*/
.profile-section {
  width: 100%;
  display: flex;
  padding-top: 4rem;
}
.profile-section .profile-subcontainer {
  width: 1024px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 5rem 1rem 0 27%;
  row-gap: 4rem;
}
.profile-section .profile-subcontainer .profile-deco {
  position: fixed;
  bottom: 0;
  right: 0;
  width: auto;
  height: 90vh;
  z-index: -1;
}

@media screen and (min-width: 320px) and (max-width: 479px) {
  .profile-subcontainer {
    margin: 7rem 0 4rem 0 !important;
    width: 100% !important;
  }
  .profile-subcontainer .profile-deco {
    display: none;
  }
}
@media screen and (min-width: 480px) and (max-width: 767px) {
  .profile-subcontainer {
    margin: 7rem 0 4rem 0 !important;
    width: 100% !important;
    align-items: center;
  }
  .profile-subcontainer .profile-deco {
    display: none;
  }
}
@media screen and (min-width: 768px) and (max-width: 1199px) {
  .profile-deco {
    display: none;
  }
}
@media screen and (min-width: 1200px) and (max-width: 1919px) {
  .profile-subcontainer {
    margin-left: 15% !important;
    margin-bottom: 5rem !important;
  }
}
@media screen and (min-width: 1920px) {
  .profile-section {
    justify-content: center;
  }
  .profile-section .profile-subcontainer {
    margin-left: 0 !important;
    margin-bottom: 5rem !important;
  }
}/*# sourceMappingURL=ProfileBody.css.map */