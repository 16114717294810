@import "../../sass/variables";

.popup-with-form-component {
  width: 100%;
  height: 100vh;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.637);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  .popup-with-form-container {
    background-color: $basicColorPrimary;
    max-width: 1024px;
    max-height: 35rem;
    border-radius: 1rem;
    display: flex;
    justify-content: center;
    .popup-with-form-subcontainer {
      margin: 1.5rem 3rem;
      display: flex;
      flex-direction: column;
      row-gap: 1rem;
    }
    .pwf-title {
      font-size: 1.5rem;
      font-weight: 500;
      color: $mainColorPrimary;
    }
    .pwf-desc-container {
      max-width: 25rem;
      color: $mainColorPrimary;
    }
    .pwf-form-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      row-gap: 1rem;
    }
    .pwf-input-form {
      border: 1px solid $mainColorPrimary;
      border-radius: 0.5rem;
      padding: 0.5rem 0 0.5rem 1rem;
      outline: none;
      width: 100%;
    }
    .pwf-btns-container {
      width: 100%;
      display: flex;
      column-gap: 1rem;
      justify-content: flex-end;
    }
    .pwf-btn {
      padding: 0.5rem 1rem;
      background-color: $mainColorPrimary;
      color: $basicColorPrimary;
      font-weight: bold;
      border: 1px solid $mainColorPrimary;
      border-radius: 0.5rem;
      cursor: pointer;
      &:hover {
        background-color: $basicColorPrimary;
        color: $mainColorPrimary;
      }
    }
    .cancel-btn {
      background-color: $alertBtnColorPrimary;
      border: 1px solid $alertBtnColorPrimary;
      &:hover {
        color: $alertBtnColorPrimary;
      }
    }
  }
}
