@import '../../sass/variables';

.info-txt-container {
    background-color: $basicColorPrimary;
    color: $mainColorPrimary;
    padding: 0.5rem 1rem;
    border: 1px solid $mainColorPrimary;
    border-radius: 0.5rem;
    font-size: 0.8rem;
    position: absolute;
}