@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@1,700&display=swap");
/*Colors*/
/*Colors Transparency*/
/*Hover Colors*/
/*Button Color Effect*/
/*Styles*/
* {
  margin: 0;
}

/*Responsive - breakpoints*/
.body-container {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}
.body-container .body-subcontainer {
  width: 100%;
}

.no-scroll {
  overflow: hidden;
  height: 100vh;
}

@media screen and (min-width: 320px) and (max-width: 479px) {
  .body-container {
    display: unset;
  }
}/*# sourceMappingURL=ClientHome.css.map */