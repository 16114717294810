@import "../../sass/variables";

.version-txt-container {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  z-index: 999;
  .version-txt {
    font-size: 0.9rem;
    color: $barGraphListColorPrimary;
  }
}

//Responsive < Tablet Standard
@media screen and (min-width: $mobileSm) and (max-width: calc($tabletStandard - 1px)) {
  .version-txt-container {
    display: none;
  }
}
