@import "../../sass/variables";

.notification-container-mobile {
  position: fixed;
  width: 19.5rem;
  height: 16.8rem;
  background-color: $basicColorPrimary;
  overflow-y: scroll;
  z-index: 99;
  bottom: -100%;
  left: 0rem;
  overflow-x: hidden;
  border-radius: 1rem 0 0 0;
  border-top: 1px solid $mainColorPrimary;
  border-right: 1px solid $mainColorPrimary;
  border-left: 1px solid $mainColorPrimary;
  &::-webkit-scrollbar {
    width: 0.5rem;
    background-color: $basicColorPrimary;
    border-radius: 1rem;
  }
  &::-webkit-scrollbar-thumb {
    width: 0.5rem;
    background-color: $basicColorPrimary;
    border-radius: 1rem;
  }
}
.show-noti-mb {
  @keyframes showNotiMobile {
    0% {
      bottom: -100%;
    }
    100% {
      bottom: 3rem;
    }
  }
  animation-name: showNotiMobile;
  animation-duration: 1s;
  bottom: 3rem;
}

.hide-noti-mb {
  @keyframes hideNotiMobile {
    0% {
      bottom: 3rem;
    }
    100% {
      bottom: -100%;
    }
  }
  animation-name: hideNotiMobile;
  animation-duration: 2s;
  bottom: -100%;
}
.navbar-mobile-bottom-container {
  background-color: $nbBackgroundColor;
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 3rem;
  z-index: 99;
  .nb-menus-container {
    display: flex;
    align-items: center;
    justify-content: space-around;
    column-gap: 1rem;
    height: 100%;
  }
  .profile-text-container {
    color: $barGraphListColorPrimary;
  }
  .menu-container {
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
    cursor: pointer;
  }
  .line {
    border-left: 1px solid $mainColorPrimary;
    padding-left: 0.5rem;
  }
  .menu-icon {
    width: 1.2rem;
  }
  h1 {
    font-size: 0.8rem;
  }
}

//Responsive Mobile Standard - Tablet Small
@media screen and (min-width: $mobileSm) and (max-width: calc($tabletStandard - 1px)) {
  .notification-container-mobile {
    width: 24rem !important;
  }
}

//Responsive Tablet Standard >
@media screen and (min-width: $tabletStandard) {
  .notification-container-mobile {
    display: none;
  }
  .navbar-mobile-bottom-container {
    display: none !important;
  }
}
