@import "../../../sass/variables";

//Una vez generado el archivo .scss, en las extensiones activa "Watch Sass" para generar automaticamente el archivo .css del modulo, si no se genera el css, reinicia el live compiler.

.doc-certification-section {
  width: 100%;
  display: flex;
  padding-top: 4rem;
  .doc-certification-subcontainer {
    width: 1024px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: 5rem 1rem 0 27%;
    row-gap: 4rem;
    .input-container {
      display: flex;
      column-gap: 1rem;
      align-items: flex-end;
    }
    .filter-form-container {
      display: grid;
      align-items: flex-end;
      column-gap: 1rem;
      grid-template-columns: 1fr;
    }
    label {
      display: flex;
      flex-direction: column;
      font-size: 0.9rem;
      row-gap: 0.5rem;
    }
    .label-title {
      font-size: 0.9rem;
      color: $mainColorPrimary;
      font-weight: 500;
    }
    input {
      padding: 0.5rem 1rem;
      outline: none;
      border: 1px solid $barGraphListColorPrimary;
      border-radius: 0.5rem;
      cursor: pointer;
      color: $barGraphListColorPrimary;
      font-weight: 500;
    }
    select {
      padding: 0.5rem 1rem;
      outline: none;
      border: 1px solid $barGraphListColorPrimary;
      color: $barGraphListColorPrimary;
      border-radius: 0.5rem;
      cursor: pointer;
      font-weight: 500;
    }
    .c-input-search {
      padding: 0.5rem 1rem;
      border: 1px solid $mainColorPrimary;
      color: $mainColorPrimary;
      background-color: $basicColorPrimary;
      outline: none;
      border-radius: 0.5rem;
      width: 50%;
      &::placeholder {
        color: $mainColorPrimary;
      }
    }
    .c-btn-search {
      padding: 0.55rem 1rem;
      background-color: $mainColorPrimary;
      color: $basicColorPrimary;
      font-weight: bold;
      border: 1px solid $mainColorPrimary;
      cursor: pointer;
      border-radius: 0.5rem;
      height: 100%;
      width: 12rem;
      &:hover {
        background-color: $scrollBarColorPrimary;
        border-color: $scrollBarColorPrimary;
        color: $mainColorPrimary;
      }
    }
  }
}

//Responsive Mobile Small
@media screen and (min-width: $mobileSm) and (max-width: calc($mobileStandard - 1px)) {
  .doc-certification-subcontainer {
    margin: 7rem 0 4rem 0 !important;
  }
}

//Responsive Mobile Standard
@media screen and (min-width: $mobileStandard) and (max-width: calc($tabletSm - 1px)) {
  .doc-certification-subcontainer {
    margin: 7rem 0 4rem 0 !important;
    align-items: center;
    .dc-table-container {
      width: 90%;
    }
  }
}

//Responsive Tablet Small
@media screen and (min-width: $tabletSm) and (max-width: calc($tabletStandard - 1px)) {
  .doc-certification-subcontainer {
    margin: 12rem 0 4rem 0 !important;
    align-items: center;
    .form-with-steps-supercontainer {
      width: 29rem !important;
    }
    .profile-deco {
      display: none;
    }
  }
}

//Responsive Small Screen
@media screen and (min-width: $screenSm) and (max-width: calc($screenHd - 1px)) {
  .doc-certification-subcontainer {
    margin-left: 15% !important;
    margin-bottom: 5rem !important;
  }
}

//Responsive HD Screen
@media screen and (min-width: $screenHd) {
  .doc-certification-section {
    justify-content: center;
    .doc-certification-subcontainer {
      margin-left: 0 !important;
      margin-bottom: 5rem !important;
    }
  }
}
