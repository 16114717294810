@import '../../sass/variables';

.body-container {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    .body-subcontainer {
        width: 100%;
    }
}
.no-scroll {
    overflow: hidden;
    height: 100vh;
}

//Responsive Mobile Small
@media screen and (min-width: $mobileSm) and (max-width: calc($mobileStandard - 1px)) {
    .body-container {
        display: unset;
    }
}