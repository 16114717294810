@import "../../sass/variables";

.side-menu-container {
  height: 100vh;
  width: 14.5rem;
  overflow: hidden;
  z-index: 99;
  .side-menu-subcontainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    row-gap: 2rem;
    padding: 0 0 0 1.5rem;
    position: fixed;
    background-color: $mainColorPrimary;
    left: 0;
    min-width: 13rem;
    .line {
      border-top: 1px solid $basicColorPrimary;
      position: fixed;
      top: 4rem;
      width: 100%;
    }
    .logo-img-container {
      width: 9rem;
      margin-top: 1rem;
    }
    .logo-img {
      width: 100%;
    }
    .menus-container {
      display: flex;
      flex-direction: column;
      row-gap: 2rem;
      position: relative;
      width: 100%;
      overflow-y: scroll;
      overflow-x: hidden;
      height: 100vh;
      direction: rtl;
      margin-bottom: 2rem;
      & > * {
        direction: ltr;
      }
      &::-webkit-scrollbar {
        width: 0.5rem;
        height: 10px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: transparentize(
          $color: $mainColorPrimary,
          $amount: 0.5
        );
        border-radius: 5px;
      }
      &::-webkit-scrollbar-track {
        margin-right: 1rem;
        background-color: transparentize($color: $basicColorPrimary, $amount: 0.5);
        border-radius: 1rem;
      }
    }
    .mc-hide-scroll {
      overflow-y: unset;
      overflow-x: unset;
    }
    .mc-show-scroll {
      overflow-x: hidden;
      overflow-y: auto;
    }
    .option-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: $basicColorPrimary;
      row-gap: 1rem;
      &:link {
        color: $basicColorPrimary;
        text-decoration: none;
      }
      &:visited {
        color: $basicColorPrimary;
      }
      &:active {
        color: $basicColorPrimary;
      }
    }
    .current-select {
      border: 1px solid $basicColorPrimary;
      padding: 1rem;
      background-color: $basicColorPrimary;
      color: $mainColorPrimary !important;
      border-radius: 170px 0px 0px 169px;
      -webkit-border-radius: 170px 0px 0px 169px;
      -moz-border-radius: 170px 0px 0px 169px;
    }
    .option-icon {
      width: 2.5rem;
    }
    .title-option {
      width: 50%;
      text-align: center;
      font-weight: bold;
      font-size: 0.7rem;
    }
    .sm-footer-info-container {
      display: flex;
      align-items: center;
      column-gap: 1rem;
      margin-bottom: 3rem;
    }
    .sm-info-logo {
      font-size: 0.8rem;
      border-bottom: 2px solid $basicColorPrimary;
      border-top: 2px solid $basicColorPrimary;
      color: $basicColorPrimary;
      width: 4.5rem;
      text-align: center;
    }
    .sm-info-txt {
      font-size: 0.5rem;
      color: $basicColorPrimary;
      width: 7.5rem;
      text-align: center;
    }
  }
  .sms-remove-right-padding {
    padding: 0 0 0 1.25rem !important;
  }
}

//Responsive Mobile Small
@media screen and (min-width: $mobileSm) and (max-width: calc($mobileStandard - 1px)) {
  .side-menu-container {
    display: none;
  }
}

//Responsive Mobile Standard
@media screen and (min-width: $mobileStandard) and (max-width: calc($tabletSm - 1px)) {
  .side-menu-container {
    display: none;
  }
}

//Responsive Tablet Small
@media screen and (min-width: $tabletSm) and (max-width: calc($tabletStandard - 1px)) {
  .side-menu-container {
    display: none;
  }
}
