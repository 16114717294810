@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@1,700&display=swap");
/*Colors*/
/*Colors Transparency*/
/*Hover Colors*/
/*Button Color Effect*/
/*Styles*/
* {
  margin: 0;
}

/*Responsive - breakpoints*/
.form-with-steps-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #ffffff;
  border: 1px solid #014da1;
  border-radius: 0.5rem;
  position: relative;
  box-shadow: 10px 6px 18px -8px rgba(0, 0, 0, 0.67);
  row-gap: 1rem;
  margin-bottom: 1.5rem;
}
.form-with-steps-container .header-title-container {
  background-color: #014da1;
  border: 1px solid #014da1;
  border-top-right-radius: 0.5rem;
  border-top-left-radius: 0.5rem;
  color: #ffffff;
  padding: 1rem 3rem;
  text-align: center;
  font-size: 0.9rem;
}
.form-with-steps-container .steps-graph-container {
  padding: 0 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1rem;
  font-weight: bold;
  color: #014da1;
}
.form-with-steps-container .steps-graph-container .step-bar-container {
  width: 10rem;
  height: 0.5rem;
  border-radius: 0.5rem;
  background-color: #868686;
}
.form-with-steps-container .steps-graph-container .step-bar-container .progress-bar {
  height: 0.5rem;
  background-color: #42ffff;
  border-radius: 0.5rem;
}
.form-with-steps-container .reset-btn-container {
  padding-left: 2rem;
}
.form-with-steps-container .reset-btn-container .reset-btn {
  padding: 0.25rem 0.5rem;
  background-color: #42ffff;
  color: #014da1;
  outline: none;
  border: 1px solid #42ffff;
  cursor: pointer;
  border-radius: 0.5rem;
  font-weight: bold;
}
.form-with-steps-container .reset-btn-container .reset-btn:hover {
  background-color: #014da1;
  color: #ffffff;
  border-color: #014da1;
}
.form-with-steps-container .fws-form-container {
  padding: 0 2rem 2rem 2rem;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}
.form-with-steps-container .fws-form-container .current-step {
  display: grid;
  align-items: flex-end;
  -moz-column-gap: 1rem;
       column-gap: 1rem;
}
.form-with-steps-container .fws-form-container .full-width-input {
  grid-template-columns: 1fr !important;
}
.form-with-steps-container .fws-form-container .half-width {
  grid-template-columns: 1fr 1fr !important;
}
.form-with-steps-container .fws-form-container label {
  display: flex;
  flex-direction: column;
  color: #014da1;
  font-style: italic;
}
.form-with-steps-container .fws-form-container input {
  margin-top: 0.5rem;
  padding: 0.5rem 0;
  padding-left: 1rem;
  border-radius: 0.5rem !important;
  border: 2px solid lightgray !important;
  width: 90%;
  font-family: "Roboto", sans-serif;
}
.form-with-steps-container .fws-form-container select {
  margin-top: 0.5rem;
  padding: 0.5rem 0;
  padding-left: 1rem;
  border-radius: 0.5rem !important;
  border: 2px solid lightgray !important;
  width: 100%;
  font-family: "Roboto", sans-serif;
}
.form-with-steps-container .fws-form-container .mandatory-text {
  font-size: 0.8rem;
  color: #ff0000;
}
.form-with-steps-container .fws-form-container .hide {
  opacity: 0;
}
.form-with-steps-container .fws-form-container .fws-btns-container {
  display: flex;
  -moz-column-gap: 1rem;
       column-gap: 1rem;
}
.form-with-steps-container .fws-form-container .fws-btn {
  width: 100%;
  height: 2.5rem;
  background-color: #42ffff;
  color: #014da1;
  outline: none;
  border: 1px solid #42ffff;
  cursor: pointer;
  border-radius: 0.5rem;
  font-weight: bold;
}
.form-with-steps-container .fws-form-container .fws-btn:hover {
  background-color: #014da1;
  color: #ffffff;
  border-color: #014da1;
}
.form-with-steps-container .fws-form-container .radio-inputs-container {
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
}

@media screen and (min-width: 320px) and (max-width: 479px) {
  .current-step {
    grid-template-columns: unset !important;
  }
  input {
    width: unset !important;
  }
}/*# sourceMappingURL=FormWithStepsComponent.css.map */