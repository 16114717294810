@import "../../sass/variables";

.payment-method-section {
  width: 100%;
  display: flex;
  padding-top: 0;
  .payment-method-subcontainer {
    width: 1024px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: 7rem 1rem 0 27%;
    row-gap: 4rem;
    .pm-info-container {
      max-width: 32rem;
      background-color: $basicColorPrimary;
      border: 1px solid $mainColorPrimary;
      border-radius: 1rem;
      box-shadow: 10px 10px 25px -8px rgba(0, 0, 0, 0.4);
      -webkit-box-shadow: 10px 10px 25px -8px rgba(0, 0, 0, 0.4);
      -moz-box-shadow: 10px 10px 25px -8px rgba(0, 0, 0, 0.4);
    }
    .pm-info-subcontainer {
      margin: 2rem 1.5rem;
      color: $mainColorPrimary;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      row-gap: 1.5rem;
    }
    .pm-info-img {
      width: 10rem;
      height: auto;
    }
    .pm-info-content-container {
      display: flex;
      flex-direction: column;
      row-gap: 1rem;
    }
    .pm-ic-title {
      font-size: 1.1rem;
    }
    .pm-ic-elms-container {
      display: flex;
      flex-direction: column;
      row-gap: 1rem;
    }
    .pm-ic-e-title {
      font-size: 1rem;
    }
    .pm-ic-list-container {
      font-size: 0.9rem;
    }
    .pm-ic-advice-content-container {
      display: flex;
      flex-direction: column;
      row-gap: 1rem;
      font-size: 0.8rem;
    }
    .pm-ic-se-le-anchor {
      color: $mainColorPrimary;
      font-weight: bold;
    }
    .pm-deco {
      position: fixed;
      bottom: 0;
      right: 0;
      width: 45%;
      z-index: -1;
    }
  }
}

//Responsive Mobile Small
@media screen and (min-width: $mobileSm) and (max-width: calc($mobileStandard - 1px)) {
  .payment-method-subcontainer {
    margin: 10rem 0 4rem 0 !important;
    width: 100% !important;
    .pm-deco {
      display: none;
    }
  }
}

//Responsive Mobile Standard
@media screen and (min-width: $mobileStandard) and (max-width: calc($tabletSm - 1px)) {
  .payment-method-subcontainer {
    margin: 10rem 0 4rem 0 !important;
    width: 100% !important;
    align-items: center;
    .pm-deco {
      display: none;
    }
  }
}

//Responsive Tablet Small
@media screen and (min-width: $tabletSm) and (max-width: calc($tabletStandard - 1px)) {
  .payment-method-subcontainer {
    margin: 10rem 0 4rem 15% !important;
    .pm-deco {
      display: none;
    }
  }
}

//Responsive Small Screen
@media screen and (min-width: $screenSm) and (max-width: calc($screenHd - 1px)) {
  .payment-method-subcontainer {
    margin-left: 15% !important;
    margin-bottom: 5rem !important;
  }
}

//Responsive HD Screen
@media screen and (min-width: $screenHd) {
  .payment-method-section {
    justify-content: center;
    .payment-method-subcontainer {
      margin-left: 0 !important;
      // margin-bottom: 5rem !important;
    }
  }
}
