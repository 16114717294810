@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@1,700&display=swap");
/*Colors*/
/*Colors Transparency*/
/*Hover Colors*/
/*Button Color Effect*/
/*Styles*/
* {
  margin: 0;
}

/*Responsive - breakpoints*/
.form-container-docs {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #ffffff;
  border: 1px solid #014da1;
  border-radius: 1rem;
  position: relative;
  box-shadow: 10px 6px 18px -8px rgba(0, 0, 0, 0.67);
  padding: 2rem;
  row-gap: 1rem;
  margin-bottom: 1.5rem;
}
.form-container-docs .txt-input-container {
  display: grid;
  align-items: flex-end;
  grid-template-columns: 1fr;
  -moz-column-gap: 1rem;
       column-gap: 1rem;
}
.form-container-docs .txt-input-container label {
  display: flex;
  flex-direction: column;
  color: #014da1;
}
.form-container-docs .txt-input-container input {
  margin-top: 0.5rem;
  padding: 0.5rem 0;
  padding-left: 1rem;
  border-radius: 0.5rem !important;
  border: 2px solid #014da1 !important;
  width: 92%;
  font-family: "Roboto", sans-serif;
}
.form-container-docs .select-label {
  display: flex;
  flex-direction: column;
  color: #014da1;
  font-style: italic;
}
.form-container-docs .hide-label {
  display: none !important;
}
.form-container-docs select {
  margin-top: 0.5rem;
  padding: 0.5rem 0;
  padding-left: 1rem;
  border-radius: 0.5rem !important;
  border: 2px solid #014da1 !important;
  width: 100%;
  font-family: "Roboto", sans-serif;
}
.form-container-docs .inputs-file-container {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}
.form-container-docs .inputs-file-container label {
  display: flex;
  flex-direction: column;
  color: #014da1;
}
.form-container-docs .inputs-file-container input[type=file]::file-selector-button {
  margin: 0.5rem 0 0.25rem 0;
  border: 2px solid #42ffff;
  padding: 0.2em 0.4em;
  border-radius: 0.2em;
  background-color: #42ffff;
  transition: 1s;
  cursor: pointer;
  color: #014da1;
}
.form-container-docs .inputs-file-container input[type=file]::file-selector-button:hover {
  background-color: #014da1;
  border: 2px solid #014da1;
  color: #ffffff;
}
.form-container-docs .inputs-file-container input[type=file]::-ms-browse {
  background-color: #42ffff;
  border: 2px solid #42ffff;
  cursor: pointer;
  color: #014da1;
}
.form-container-docs .inputs-file-container input[type=file]::-ms-browse:hover {
  background-color: #014da1;
  border: 2px solid #014da1;
  color: #ffffff;
}
.form-container-docs .inputs-file-container input[type=file]::-webkit-file-upload-button {
  background-color: #42ffff;
  border: 2px solid #42ffff;
  cursor: pointer;
  color: #014da1;
}
.form-container-docs .inputs-file-container input[type=file]::-webkit-file-upload-button:hover {
  background-color: #014da1;
  border: 2px solid #014da1;
  color: #ffffff;
}
.form-container-docs .inputs-file-container .input-subtitle {
  font-size: 0.8rem;
}
.form-container-docs .form-doc-btn-container {
  width: 100%;
  display: flex;
  justify-content: center;
  -moz-column-gap: 1rem;
       column-gap: 1rem;
}
.form-container-docs .form-doc-btn-container .form-doc-btn {
  width: 100%;
  height: 2.5rem;
  background-color: #42ffff;
  color: #014da1;
  outline: none;
  border: 1px solid #42ffff;
  cursor: pointer;
  border-radius: 0.5rem;
  font-weight: bold;
}
.form-container-docs .form-doc-btn-container .form-doc-btn:hover {
  background-color: #014da1;
  color: #ffffff;
  border-color: #014da1;
}

@media screen and (min-width: 320px) and (max-width: 479px) {
  .form-container-docs {
    width: unset;
  }
  .form-container-docs .txt-input-container {
    grid-template-columns: unset;
    row-gap: 1rem;
  }
}/*# sourceMappingURL=FormDocsComponent.css.map */