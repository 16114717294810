@import "../../sass/variables";

.popup-bg {
  width: 100%;
  height: 100vh;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.637);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  .popup-container {
    background-color: $basicColorPrimary;
    min-width: 15%;
    max-width: 40%;
    padding: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    row-gap: 1rem;
    border-radius: 1rem;
    .info-arr-container {
      display: flex;
      flex-direction: column;
      row-gap: 1rem;
    }
    .popup-icon {
      width: 6rem;
    }
    .popup-msg {
      text-align: center;
    }
    .popup-option-container {
      width: 100%;
      display: flex;
      column-gap: 3rem;
      justify-content: center;
    }
  }
}

//Responsive Mobile Small - Tablet Standard
@media screen and (min-width: $mobileSm) and (max-width: calc($tabletStandard - 1px)) {
  .popup-container {
    max-width: 80% !important;
    font-size: 0.8rem;
  }
}
