@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@1,700&display=swap");
/*Colors*/
/*Colors Transparency*/
/*Hover Colors*/
/*Button Color Effect*/
/*Styles*/
* {
  margin: 0;
}

/*Responsive - breakpoints*/
.login {
  padding: 0.25rem 2rem;
  border-radius: 1rem;
  outline: none;
  cursor: pointer;
  background-color: #014da1;
  color: #ffffff;
  font-weight: bold;
  border: 1px solid #014da1;
}
.login:hover {
  color: #014da1;
  background-color: #ffffff;
  border: 1px solid #014da1;
}
.login:hover:visited {
  color: #014da1;
}
.login:link {
  color: #ffffff;
  text-decoration: none;
}
.login:active {
  color: #ffffff;
}/*# sourceMappingURL=ButtonComponent.css.map */