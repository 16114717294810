@import '../../sass/variables';

.stp-table-info-container {
    width: 100%;
    padding: 1rem 0;
    color: $mainColorPrimary;
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    .stp-title {
        width: 100%;
        text-align: center;
        font-size: 1.5rem;
        font-weight: 500;
    }
    .stp-table-container {
        display: grid;
        grid-template-columns: 40% 60%;
        margin: 0 20%;
        column-gap: 1rem;
        .stp-table-elm {
            border: 1px solid $mainColorPrimary;
            width: 45.5rem;
            border-radius: 0.5rem;
            margin-left: -15%;
        }
        .stp-table-row-header {
            border-bottom: 1px solid $mainColorPrimary;
            text-align: center;
            padding: 0.5rem 0;
        }
        .stp-table-column-header {
            border-right: 1px solid $mainColorPrimary;
            padding: 0.5rem 0;
            border-bottom: 1px solid $mainColorPrimary;
        }
        .last {
            border-right: none;
        }
        .stp-table-row-content {
            text-align: center;
        }
        .last-row {
            border-bottom: none;
        }
    }
}

//Responsive Mobile Small
@media screen and (min-width: $mobileSm) and (max-width: calc($mobileStandard - 1px)) {
    .stp-table-container {
        overflow-y:  scroll;
        width: 80vw;
        padding-left: 1.5rem !important;
        margin: 0 5% !important;
    }
}

//Responsive Mobile Standard
@media screen and (min-width: $mobileStandard) and (max-width: calc($tabletSm - 1px)) {
    .stp-table-container {
        overflow-y:  scroll;
        width: 80vw;
        padding-left: 1.5rem !important;
        margin: 0 5% !important;
    }
}

//Responsive Tablet Small
@media screen and (min-width: $tabletSm) and (max-width: calc($tabletStandard - 1px)) {
    .stp-table-container {
        margin: 0 8% !important;
    }
}

//Responsive Tablet Standard
@media screen and (min-width: $tabletStandard) and (max-width: calc($screenSm - 1px)) {
    .stp-table-container {
        margin: 0 6% !important;
    }
}

//Responsive Screen Small
@media screen and (min-width: $screenSm) and (max-width: calc($screenHd - 1px)) {
    .stp-table-container {
        margin: 0 12% !important;
    }
}