@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@1,700&display=swap");
/*Colors*/
/*Colors Transparency*/
/*Hover Colors*/
/*Button Color Effect*/
/*Styles*/
* {
  margin: 0;
}

/*Responsive - breakpoints*/
.info-txt-container {
  background-color: #ffffff;
  color: #014da1;
  padding: 0.5rem 1rem;
  border: 1px solid #014da1;
  border-radius: 0.5rem;
  font-size: 0.8rem;
  position: absolute;
}/*# sourceMappingURL=InfoTextComponent.css.map */