@import "../../sass/variables";

.popup-with-table-component {
  width: 100%;
  height: 100vh;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.637);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  .popup-with-table-container {
    background-color: $basicColorPrimary;
    max-width: 1024px;
    max-height: 35rem;
    border-radius: 1rem;
    display: flex;
    justify-content: center;
    .popup-with-table-subcontainer {
      margin: 1.5rem 3rem;
      display: flex;
      flex-direction: column;
      row-gap: 1rem;
    }
    .pwt-title {
      font-size: 1.8rem;
      font-weight: 500;
      color: $mainColorPrimary;
    }
    .pwtdt-txt {
      color: $mainColorPrimary;
    }
    .pwt-table-container {
      max-height: 30rem;
      overflow-y: scroll;
    }
    .pwt-btns-container {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      column-gap: 1rem;
    }
    .pwt-btn-close {
      padding: 0.5rem 1.5rem;
      background-color: $alertBtnColorPrimary;
      border: 1px solid $alertBtnColorPrimary;
      color: $basicColorPrimary;
      font-weight: 600;
      border-radius: 0.5rem;
      cursor: pointer;
      &:hover {
        background-color: $basicColorPrimary;
        color: $alertBtnColorPrimary;
      }
    }
    .submit {
      background-color: $mainColorPrimary;
      border: 1px solid $mainColorPrimary;
      &:hover {
        color: $mainColorPrimary;
      }
    }
  }
}
