@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@1,700&display=swap");
/*Colors*/
/*Colors Transparency*/
/*Hover Colors*/
/*Button Color Effect*/
/*Styles*/
* {
  margin: 0;
}

/*Responsive - breakpoints*/
.credit-detail-table-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  transform: rotateX(180deg);
  overflow-x: auto;
}
.credit-detail-table-container .cdt-download-docs-container {
  width: 28.75%;
  align-self: flex-end;
}
.credit-detail-table-container .cdt-download-docs-container .cdt-download-docs-subcontainer {
  background-color: #014da1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 1rem 1rem;
  row-gap: 1rem;
}
.credit-detail-table-container .cdt-download-docs-container .cdt-dd-title {
  font-size: 1rem;
  color: #ffffff;
  font-weight: 300;
}
.credit-detail-table-container .cdt-download-docs-container .cdt-dd-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}
.credit-detail-table-container .cdt-download-docs-container .cdt-dd-btns-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  -moz-column-gap: 1rem;
       column-gap: 1rem;
}
.credit-detail-table-container .cdt-download-docs-container .cdt-dd-btns {
  padding: 0.55rem 1rem;
  background-color: #42ffff;
  color: #014da1;
  font-weight: bold;
  border: 1px solid #42ffff;
  cursor: pointer;
  border-radius: 0.5rem;
  height: 100%;
  width: 100%;
}
.credit-detail-table-container .cdt-download-docs-container .cdt-dd-btns:hover {
  background-color: #ffffff;
  border-color: #42ffff;
  color: #014da1;
}
.credit-detail-table-container .cdt-table-container {
  border-collapse: collapse;
  transform: rotateX(180deg);
}
.credit-detail-table-container .cdt-table-container .cdt-table-row {
  color: #014da1;
  border-bottom: 1px solid #014da1;
}
.credit-detail-table-container .cdt-table-container .cdt-table-header-title {
  padding: 0.5rem 1rem;
  align-items: center;
  border-bottom: 1px solid #014da1;
}
.credit-detail-table-container .cdt-table-container .actions-btn {
  display: flex;
  border-bottom: none;
  -moz-column-gap: 0.5rem;
       column-gap: 0.5rem;
}
.credit-detail-table-container .cdt-table-container .centered {
  min-height: 8rem;
  display: flex;
  justify-content: center;
  border-bottom: none;
}
.credit-detail-table-container .cdt-table-container .left-border {
  border-left: 1px solid #014da1;
}
.credit-detail-table-container .cdt-table-container .right-border {
  border-right: 1px solid #014da1;
}
.credit-detail-table-container .cdt-table-container .checkbox-input {
  width: 1.2rem;
  height: 1.2rem;
}
.credit-detail-table-container .cdt-table-container .cdt-table-checkbox-block {
  width: 1.5rem;
  height: 1.5rem;
}
.credit-detail-table-container .cdt-table-container .cdt-tht-action-btn {
  border: 1px solid #014da1;
  background-color: #014da1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  border-radius: 100%;
  cursor: pointer;
  color: #ffffff;
}
.credit-detail-table-container .cdt-table-container .cdt-tht-action-btn:hover {
  color: #014da1;
  background-color: #ffffff;
}
.credit-detail-table-container .cdt-table-container .cdt-tht-action-btn .cdt-tht-action-btn-icon {
  width: auto;
  height: 1rem;
}
.credit-detail-table-container .cdt-table-container .danger {
  background-color: #ff0000;
  border-color: #ff0000;
}
.credit-detail-table-container .cdt-table-container .danger-txt {
  color: #ff0000;
}
.credit-detail-table-container .cdt-table-container .has_text_radius {
  border-radius: 1rem;
  min-width: 8rem;
}

.cdt-pagination-elms {
  padding-top: 1rem;
}
.cdt-pagination-elms .pagination-btns-container {
  display: flex;
  align-items: center;
  -moz-column-gap: 2rem;
       column-gap: 2rem;
}
.cdt-pagination-elms .pagination-btns {
  display: flex;
  -moz-column-gap: 1rem;
       column-gap: 1rem;
  color: #868686;
}
.cdt-pagination-elms .pagination-btn {
  background-color: #014da1;
  border: 1px solid #014da1;
  color: #ffffff;
  border-radius: 0.25rem;
  cursor: pointer;
  font-weight: bold;
  font-size: 1.2rem;
}
.cdt-pagination-elms .pagination-btn:hover {
  background-color: #42ffff;
  border-color: #42ffff;
  color: #014da1;
}
.cdt-pagination-elms .pagination-info {
  color: #868686;
}
.cdt-pagination-elms .select-max-data {
  width: 5rem !important;
}

@media screen and (min-width: 320px) and (max-width: 479px) {
  .cdt-download-docs-subcontainer {
    position: relative;
    left: -13rem;
    width: 15rem;
  }
  .cdt-download-docs-container {
    width: 25.5% !important;
  }
}
@media screen and (min-width: 480px) and (max-width: 767px) {
  .cdt-download-docs-subcontainer {
    position: relative;
    left: -13rem;
    width: 15rem;
  }
  .cdt-download-docs-container {
    width: 35% !important;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .cdt-download-docs-subcontainer {
    position: relative;
    left: -13rem;
    width: 15rem;
  }
  .cdt-download-docs-container {
    width: 35% !important;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1199px) {
  .cdt-download-docs-subcontainer {
    position: relative;
    left: -13rem;
    width: 15rem;
  }
  .cdt-download-docs-container {
    width: 35% !important;
  }
}
@media screen and (min-width: 1200px) and (max-width: 1919px) {
  .cdt-download-docs-subcontainer {
    position: relative;
    left: 3.2rem;
    width: 12.9rem;
  }
  .cdt-download-docs-container {
    width: 31% !important;
  }
}/*# sourceMappingURL=CreditDetailsTableComponent.css.map */