@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@1,700&display=swap");
/*Colors*/
/*Colors Transparency*/
/*Hover Colors*/
/*Button Color Effect*/
/*Styles*/
* {
  margin: 0;
}

/*Responsive - breakpoints*/
.simulator-section {
  width: 100%;
  display: flex;
  padding-top: 4rem;
}
.simulator-section .hand-deco {
  position: fixed;
  bottom: 0;
  left: 20%;
}
.simulator-section .simulator-subcontainer {
  width: 1024px;
  display: grid;
  justify-content: space-between;
  align-items: center;
  margin: 5rem 1rem 0 27%;
  row-gap: 4rem;
}
.simulator-section .simulator-subcontainer .pmi-elm-icon {
  width: 3rem;
}
.simulator-section .simulator-subcontainer .sim-calculator-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  -moz-column-gap: 1rem;
       column-gap: 1rem;
}
.simulator-section .simulator-subcontainer .sim-calculator-elm-container {
  background-color: #014da1;
  border-radius: 0.5rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  align-items: center;
  box-shadow: 10px 10px 19px -4px rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: 10px 10px 19px -4px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 10px 10px 19px -4px rgba(0, 0, 0, 0.25);
}
.simulator-section .simulator-subcontainer .sce-title {
  font-size: 1.1rem;
  font-weight: 300;
  text-align: center;
  color: #ffffff;
  width: 100%;
}
.simulator-section .simulator-subcontainer .sce-inputs-container {
  color: #ffffff;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}
.simulator-section .simulator-subcontainer .sce-grid-inputs {
  display: grid;
  grid-template-columns: 50% 50%;
  align-items: flex-end;
  -moz-column-gap: 1rem;
       column-gap: 1rem;
}
.simulator-section .simulator-subcontainer .label-subtitle {
  font-size: 1rem;
  font-weight: 200;
}
.simulator-section .simulator-subcontainer .style-label {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
}
.simulator-section .simulator-subcontainer .radio-btns-container {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
}
.simulator-section .simulator-subcontainer .radio-opt-container {
  display: flex;
  justify-content: space-between;
}
.simulator-section .simulator-subcontainer .radio-label {
  display: flex;
  -moz-column-gap: 1rem;
       column-gap: 1rem;
  align-items: center;
}
.simulator-section .simulator-subcontainer .payment-info-container {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}
.simulator-section .simulator-subcontainer .pi-title {
  color: #ffffff;
  font-size: 1.1rem;
  font-weight: 300;
}
.simulator-section .simulator-subcontainer .payment-methods-info-container {
  display: grid;
  grid-template-columns: 40% 60%;
  -moz-column-gap: 0.5rem 0 0.5rem 1rem;
       column-gap: 0.5rem 0 0.5rem 1rem;
}
.simulator-section .simulator-subcontainer .advise-container {
  font-size: 0.7rem;
  color: #ffffff;
  margin-top: 1rem;
}
.simulator-section .simulator-subcontainer .pmi-elm-container {
  display: flex;
  align-items: center;
  -moz-column-gap: 1rem;
       column-gap: 1rem;
  font-size: 0.8rem;
  color: #ffffff;
}
.simulator-section .simulator-subcontainer .bordered {
  border-left: 1px solid #ffffff;
  padding-left: 0.5rem;
}
.simulator-section .simulator-subcontainer .input-txt {
  padding: 0.5rem 0 0.5rem 2rem;
  background-color: #014da1;
  border: 1px solid #ffffff;
  outline: none;
  border-radius: 1rem;
  color: #ffffff;
}
.simulator-section .simulator-subcontainer .input-txt::-moz-placeholder {
  color: #ffffff;
}
.simulator-section .simulator-subcontainer .input-txt::placeholder {
  color: #ffffff;
}
.simulator-section .simulator-subcontainer .date {
  position: relative;
  padding-right: 0.9rem;
}
.simulator-section .simulator-subcontainer .date::after {
  content: "📆";
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-55%);
  font-size: 1.1rem;
  pointer-events: none;
  cursor: pointer;
}
.simulator-section .simulator-subcontainer .date-info {
  top: 10rem !important;
}
.simulator-section .simulator-subcontainer .init-premium-info {
  top: 16rem !important;
}
.simulator-section .simulator-subcontainer .date-label-container {
  display: flex;
  align-items: center;
  -moz-column-gap: 0.5rem;
       column-gap: 0.5rem;
}
.simulator-section .simulator-subcontainer .input-select {
  padding: 0.5rem;
  background-color: #014da1;
  border: 1px solid #ffffff;
  border-radius: 1rem;
  color: #ffffff;
  outline: none;
}
.simulator-section .simulator-subcontainer .input-radio {
  cursor: pointer;
}
.simulator-section .simulator-subcontainer .label-subtitle-with-info {
  display: flex;
  -moz-column-gap: 0.5rem;
       column-gap: 0.5rem;
}
.simulator-section .simulator-subcontainer .info-subtitle-container {
  position: absolute;
  width: 25rem;
  top: 20rem;
}
.simulator-section .simulator-subcontainer .subtitle-info-icon {
  width: 1rem;
  height: 1rem;
  cursor: pointer;
}
.simulator-section .simulator-subcontainer .sim-calculator-result-container {
  border: 1px solid #014da1;
  border-radius: 0.5rem;
  padding: 2rem 1rem;
  display: flex;
  flex-direction: column;
  row-gap: 3rem;
  align-items: center;
  box-shadow: 10px 10px 19px -4px rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: 10px 10px 19px -4px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 10px 10px 19px -4px rgba(0, 0, 0, 0.25);
}
.simulator-section .simulator-subcontainer .scr-title {
  font-size: 1rem;
  color: #ffffff;
  border: 1px solid #014da1;
  background-color: #014da1;
  padding: 0.25rem 1rem;
  text-align: center;
  border-radius: 1rem;
  font-weight: 300;
}
.simulator-section .simulator-subcontainer .scr-info-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  color: #014da1;
}
.simulator-section .simulator-subcontainer .src-info-subcontainer {
  display: flex;
  justify-content: space-between;
  padding: 0 1rem;
}
.simulator-section .simulator-subcontainer .deco-field {
  background-color: #ffffff;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
}
.simulator-section .simulator-subcontainer .advise {
  font-size: 0.8rem;
}
.simulator-section .simulator-subcontainer .generate-file-btn-container {
  width: 100%;
  display: flex;
  justify-content: center;
  -moz-column-gap: 1rem;
       column-gap: 1rem;
  color: transparent;
}
.simulator-section .simulator-subcontainer .print-btn {
  padding: 0.5rem;
  width: 100%;
  background-color: #42ffff;
  border: 1px solid #42ffff;
  color: #014da1;
  font-weight: bold;
  border-radius: 0.5rem;
  cursor: pointer;
}
.simulator-section .simulator-subcontainer .print-btn:hover {
  background-color: #014da1;
  border-color: #014da1;
  color: #42ffff;
}

@media screen and (min-width: 320px) and (max-width: 479px) {
  .hand-deco {
    display: none;
  }
  .simulator-subcontainer {
    margin: 7rem 0 5rem 0 !important;
  }
  .simulator-subcontainer .sim-calculator-container {
    grid-template-columns: 1fr !important;
    row-gap: 2rem;
  }
  .simulator-subcontainer .sim-calculator-container .pmi-elm-container {
    font-size: 0.5rem;
  }
  .simulator-subcontainer .sim-calculator-container .sce-grid-inputs {
    grid-template-columns: 1fr;
  }
  .simulator-subcontainer .sim-calculator-container .input-select {
    width: 100%;
  }
  .simulator-subcontainer .sim-calculator-container .radio-opt-container {
    -moz-column-gap: 1rem;
         column-gap: 1rem;
  }
  .simulator-subcontainer .sim-calculator-container .payment-methods-info-container {
    grid-template-columns: 50% 50%;
  }
}
@media screen and (min-width: 480px) and (max-width: 767px) {
  .hand-deco {
    display: none;
  }
  .simulator-subcontainer {
    margin: 7rem 0 5rem 0 !important;
    justify-content: center !important;
  }
  .simulator-subcontainer .sim-calculator-container {
    grid-template-columns: 1fr !important;
    row-gap: 2rem;
    margin: 0 10%;
  }
  .simulator-subcontainer .sim-calculator-container .pmi-elm-container {
    font-size: 0.5rem;
  }
  .simulator-subcontainer .sim-calculator-container .sce-grid-inputs {
    grid-template-columns: 1fr;
  }
  .simulator-subcontainer .sim-calculator-container .input-select {
    width: 100%;
  }
  .simulator-subcontainer .sim-calculator-container .radio-opt-container {
    -moz-column-gap: 1rem;
         column-gap: 1rem;
  }
  .simulator-subcontainer .sim-calculator-container .payment-methods-info-container {
    grid-template-columns: 50% 50%;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .hand-deco {
    display: none;
  }
  .simulator-subcontainer {
    margin: 12rem 0 5rem 0 !important;
    justify-content: center !important;
  }
  .simulator-subcontainer .sim-calculator-container {
    margin: 0 5%;
  }
  .simulator-subcontainer .sim-calculator-container .pmi-elm-container {
    font-size: 0.5rem;
  }
  .simulator-subcontainer .sim-calculator-container .sce-grid-inputs {
    grid-template-columns: 1fr;
  }
  .simulator-subcontainer .sim-calculator-container .input-select {
    width: 100%;
  }
  .simulator-subcontainer .sim-calculator-container .radio-opt-container {
    -moz-column-gap: 1rem;
         column-gap: 1rem;
  }
  .simulator-subcontainer .sim-calculator-container .payment-methods-info-container {
    grid-template-columns: 50% 50%;
  }
}
@media screen and (min-width: 1200px) and (max-width: 1919px) {
  .hand-deco {
    bottom: -7rem !important;
  }
  .simulator-subcontainer {
    margin-left: 15% !important;
    margin-bottom: 5rem !important;
  }
}
@media screen and (min-width: 1920px) {
  .simulator-section {
    justify-content: center;
  }
  .simulator-section .hand-deco {
    left: 10% !important;
  }
  .simulator-section .simulator-subcontainer {
    margin-left: 0 !important;
    margin-bottom: 5rem !important;
  }
}/*# sourceMappingURL=SimulatorBody.css.map */