@import "../../sass/variables";

.pie-graph-container {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  .total-container {
    font-size: 1.1rem;
    color: $mainColorPrimary;
    text-align: center;
  }
}
.pg-title {
  font-size: 1.3rem;
  color: $barGraphListColorPrimary;
  font-weight: 500;
  margin-bottom: 1rem;
}
.pg-info-container {
  font-size: 0.8rem;
  color: $mainColorPrimary;
}
.subtitle-info-icon {
  width: 1rem;
  height: 1rem;
  cursor: pointer;
}
.bg-info-container {
  font-size: 0.8rem;
  color: $mainColorPrimary;
}
.info-subtitle-container {
  position: absolute;
  width: 25rem;
  top: 9rem;
}
.pg-title-container {
  display: flex;
  align-items: baseline;
  column-gap: 0.5rem;
}
.pg-elms-container {
  display: grid;
  grid-template-columns: 70% 30%;
  column-gap: 1rem;
  justify-content: flex-end;
  align-items: center;
  row-gap: 0.5rem;
  .pg-total-container {
    width: 100%;
    text-align: center;
    .pg-total-num {
      font-size: 1rem;
      color: $mainColorPrimary;
    }
  }
  .pg-elm-info {
    font-size: 0.8rem;
    color: $mainColorPrimary;
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
  }
  .pg-elm-container {
    position: relative;
  }
  .pg-elm-btns-container {
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    .pg-btns {
      background-color: $basicColorPrimary;
      border: 1px solid $basicColorPrimary;
      color: $mainColorPrimary;
      border-radius: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }
}

//Responsive Mobile Small
@media screen and (min-width: $mobileSm) and (max-width: calc($mobileStandard - 1px)) {
  .pg-elms-container {
    grid-template-columns: 1fr !important;
    row-gap: 2rem;
    width: 100% !important;
  }
  .info-subtitle-container {
    width: 100vw;
    top: 13rem;
  }
}

//Responsive Mobile Standard
@media screen and (min-width: $mobileStandard) and (max-width: calc($tabletSm - 1px)) {
  .pg-elms-container {
    grid-template-columns: 1fr !important;
    row-gap: 2rem;
    justify-content: center !important;
    width: unset !important;
  }
  .pg-total-container {
    font-size: 0.7rem;
  }
  .pie-graph-container {
    margin: 0 10%;
  }
}

//Responsive Tablet Small
@media screen and (min-width: $tabletSm) and (max-width: calc($tabletStandard - 1px)) {
  .pg-elms-container {
    grid-template-columns: 1fr !important;
    row-gap: 2rem;
    justify-content: center !important;
    width: unset !important;
  }
}

//Responsive Tablet Standard
@media screen and (min-width: $tabletStandard) and (max-width: calc($screenSm - 1px)) {
  .pg-total-container {
    font-size: 0.7rem;
  }
}

//Responsive Screen Small
@media screen and (min-width: $screenSm) and (max-width: calc($screenHd - 1px)) {
  .pg-total-container {
    font-size: 0.7rem;
  }
}
