@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@1,700&display=swap");
/*Colors*/
/*Colors Transparency*/
/*Hover Colors*/
/*Button Color Effect*/
/*Styles*/
* {
  margin: 0;
}

/*Responsive - breakpoints*/
.attach-example-container {
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  border: 1px solid #014da1;
  border-radius: 1rem;
  box-shadow: 10px 6px 18px -8px rgba(0, 0, 0, 0.67);
}
.attach-example-container .attach-example-subcontainer {
  margin: 2rem;
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
}
.attach-example-container .attach-example-subcontainer .ae-txt-container {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  -moz-column-gap: 1rem;
       column-gap: 1rem;
}
.attach-example-container .attach-example-subcontainer .advice-txt-icon {
  width: 1.5rem;
  height: 100%;
  margin-top: 0.5rem;
}
.attach-example-container .attach-example-subcontainer .advice-txt {
  font-size: 1rem;
  font-weight: 400;
  color: #014da1;
}
.attach-example-container .attach-example-subcontainer .advice-img {
  width: 100%;
  height: 100%;
}/*# sourceMappingURL=AttachExampleComponent.css.map */