@import "../../sass/variables";

.bg-title {
  font-size: 1.3rem;
  color: $barGraphListColorPrimary;
  font-weight: 500;
  margin-bottom: 1rem;
}
.other-description-container {
  font-size: 0.8rem;
  color: $mainColorPrimary;
}
.scroll-container {
  display: flex;
  overflow-x: auto;
  width: 23rem;
  height: 200px;
  scroll-snap-type: x mandatory;
  &::-webkit-scrollbar {
    height: 0.5rem;
    background-color: $mainColorPrimary;
    border-radius: 1rem;
  }
  &::-webkit-scrollbar-thumb {
    height: 0.5rem;
    background-color: $scrollBarColorPrimary;
    border-radius: 1rem;
  }
  .scroll-page {
    flex: 0 0 auto;
    width: 100%;
    scroll-snap-align: start;
    &.focused {
      background-color: $mainColorPrimary;
      border-color: $barGraphListColorPrimary;
    }
  }
}

//Responsive Mobile Small
@media screen and (min-width: $mobileSm) and (max-width: calc($mobileStandard - 1px)) {
  .scroll-container {
    width: 90vw;
  }
}

//Responsive Mobile Standard
@media screen and (min-width: $mobileStandard) and (max-width: calc($tabletSm - 1px)) {
  .bar-graph-container {
    margin: 0 10%;
  }
}

//Responsive HD Screen
@media screen and (min-width: $screenHd) {
  .scroll-container {
    width: 85%;
    height: 15rem;
  }
}
