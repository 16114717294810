@import "../../sass/variables";

.list-container {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  row-gap: 0.5rem;
  .list-title {
    font-size: 1.3rem;
    color: $barGraphListColorPrimary;
    font-weight: 500;
    margin-bottom: 1rem;
  }
  .list-header {
    display: flex;
    align-items: center;
    font-size: 0.65rem;
    column-gap: 1rem;
    color: $mainColorPrimary;
    border-bottom: 1px solid $mainColorPrimary;
    padding-bottom: 0.4rem;
    justify-content: space-around;
    h1 {
      font-weight: 500;
    }
  }
  .list-info-container {
    font-size: 0.8rem;
    color: $mainColorPrimary;
  }
  .list-other-description-container {
    font-size: 0.8rem;
    color: $mainColorPrimary;
  }
  .list-subcontainer {
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
  }
  scroll-container {
    display: block;
    height: 200px;
    overflow-y: scroll;
    scroll-behavior: smooth;
    &::-webkit-scrollbar {
      width: 0.5rem;
      background-color: $barGraphListColorPrimary;
      border-radius: 1rem;
    }
    &::-webkit-scrollbar-thumb {
      width: 0.5rem;
      background-color: $scrollBarColorPrimary;
      border-radius: 1rem;
    }
  }
  scroll-page {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    height: 100%;
    font-size: 1em;
    .scroll-list-data-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      row-gap: 0.5rem;
    }
    .scroll-data-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border: 1px solid darken($nbBackgroundColor, $amount: 10);
      background-color: darken($nbBackgroundColor, $amount: 10);
      width: 90%;
      border-radius: 1rem;
      padding-left: 1rem;
    }
    .values-subcontainer {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $mainColorPrimary;
      margin-left: 2.5rem;
      padding: 0.5rem 2rem;
      color: $basicColorPrimary;
      border-radius: 1rem;
      min-width: 2.5rem;
    }
    .txt-data {
      font-size: 0.8rem;
      width: 10rem;
    }
    .num-credits {
      border: 1px solid $mainColorPrimary;
      background-color: $mainColorPrimary;
      border-radius: 1rem;
      width: 40%;
      padding: 0.4rem 0 0.4rem 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .sd-img {
      width: auto;
      max-height: 1.5rem;
    }
  }
}

//Responsive Mobile Small
@media screen and (min-width: $mobileSm) and (max-width: calc($mobileStandard - 1px)) {
  .list-container {
    align-items: unset;
  }
}

//Responsive Mobile Standard
@media screen and (min-width: $mobileStandard) and (max-width: calc($tabletSm - 1px)) {
  .list-container {
    align-items: unset;
    margin: 0 10%;
  }
}

//Responsive Tablet Small
@media screen and (min-width: $tabletSm) and (max-width: calc($tabletStandard - 1px)) {
  .list-container {
    align-items: unset;
    margin: 0 10%;
  }
}

//Responsive Small Screen
@media screen and (min-width: $screenSm) and (max-width: calc($screenHd - 1px)) {
  .list-subcontainer {
    width: 95%;
  }
}

//Responsive HD screen
@media screen and (min-width: $screenHd) {
  .list-subcontainer {
    width: 100%;
  }
}
