@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@1,700&display=swap");
/*Colors*/
/*Colors Transparency*/
/*Hover Colors*/
/*Button Color Effect*/
/*Styles*/
* {
  margin: 0;
}

/*Responsive - breakpoints*/
.version-txt-container {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  z-index: 999;
}
.version-txt-container .version-txt {
  font-size: 0.9rem;
  color: #868686;
}

@media screen and (min-width: 320px) and (max-width: 1023px) {
  .version-txt-container {
    display: none;
  }
}/*# sourceMappingURL=VersionTxtComponent.css.map */