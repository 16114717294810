@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@1,700&display=swap");
/*Colors*/
/*Colors Transparency*/
/*Hover Colors*/
/*Button Color Effect*/
/*Styles*/
* {
  margin: 0;
}

/*Responsive - breakpoints*/
.current-credit-section {
  width: 100%;
  display: flex;
  padding-top: 4rem;
}
.current-credit-section .current-credit-subcontainer {
  width: 1024px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 5rem 1rem 0 27%;
  row-gap: 4rem;
}
.current-credit-section .current-credit-subcontainer .cc-title {
  font-size: 1.8rem;
  font-weight: 500;
  color: #014da1;
}
.current-credit-section .current-credit-subcontainer .ccdt-txt {
  color: #014da1;
}
.current-credit-section .current-credit-subcontainer .cc-description-txt-container {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
}
.current-credit-section .current-credit-subcontainer .input-container {
  display: flex;
  -moz-column-gap: 1rem;
       column-gap: 1rem;
  align-items: flex-end;
}
.current-credit-section .current-credit-subcontainer .ccip-de-btn {
  padding: 0.55rem 1rem;
  background-color: #014da1;
  color: #ffffff;
  font-weight: bold;
  border: 1px solid #014da1;
  cursor: pointer;
  border-radius: 0.5rem;
  height: 100%;
  width: 12rem;
}
.current-credit-section .current-credit-subcontainer .ccip-de-btn:hover {
  background-color: #42ffff;
  border-color: #42ffff;
  color: #014da1;
}
.current-credit-section .current-credit-subcontainer .filter-form-container {
  display: grid;
  align-items: flex-end;
  -moz-column-gap: 1rem;
       column-gap: 1rem;
  grid-template-columns: 1fr 1fr;
}
.current-credit-section .current-credit-subcontainer label {
  display: flex;
  flex-direction: column;
  font-size: 0.9rem;
  row-gap: 0.5rem;
}
.current-credit-section .current-credit-subcontainer .label-title {
  font-size: 0.9rem;
  color: #014da1;
  font-weight: 500;
}
.current-credit-section .current-credit-subcontainer input {
  padding: 0.5rem 1rem;
  outline: none;
  border: 1px solid #868686;
  border-radius: 0.5rem;
  cursor: pointer;
  color: #868686;
  font-weight: 500;
}
.current-credit-section .current-credit-subcontainer select {
  padding: 0.5rem 1rem;
  outline: none;
  border: 1px solid #868686;
  color: #868686;
  border-radius: 0.5rem;
  cursor: pointer;
  font-weight: 500;
}
.current-credit-section .current-credit-subcontainer .cc-input-search {
  padding: 0.5rem 1rem;
  border: 1px solid #014da1;
  color: #014da1;
  background-color: #ffffff;
  outline: none;
  border-radius: 0.5rem;
  width: 50%;
}
.current-credit-section .current-credit-subcontainer .cc-input-search::-moz-placeholder {
  color: #014da1;
}
.current-credit-section .current-credit-subcontainer .cc-input-search::placeholder {
  color: #014da1;
}
.current-credit-section .current-credit-subcontainer .cc-btn-search {
  padding: 0.55rem 1rem;
  background-color: #014da1;
  color: #ffffff;
  font-weight: bold;
  border: 1px solid #014da1;
  cursor: pointer;
  border-radius: 0.5rem;
  height: 100%;
  width: 12rem;
}
.current-credit-section .current-credit-subcontainer .cc-btn-search:hover {
  background-color: #42ffff;
  border-color: #42ffff;
  color: #014da1;
}

@media screen and (min-width: 320px) and (max-width: 479px) {
  .current-credit-subcontainer {
    margin: 7rem 0 4rem 0 !important;
  }
}
@media screen and (min-width: 480px) and (max-width: 767px) {
  .current-credit-subcontainer {
    margin: 7rem 0 4rem 0 !important;
    align-items: center;
  }
  .current-credit-subcontainer .form-with-steps-supercontainer {
    width: 29rem !important;
  }
  .current-credit-subcontainer .profile-deco {
    display: none;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .current-credit-subcontainer {
    margin: 12rem 0 4rem 0 !important;
    align-items: center;
  }
  .current-credit-subcontainer .form-with-steps-supercontainer {
    width: 29rem !important;
  }
  .current-credit-subcontainer .profile-deco {
    display: none;
  }
}
@media screen and (min-width: 1200px) and (max-width: 1919px) {
  .current-credit-subcontainer {
    margin-left: 15% !important;
    margin-bottom: 5rem !important;
  }
}
@media screen and (min-width: 1920px) {
  .current-credit-section {
    justify-content: center;
  }
  .current-credit-section .current-credit-subcontainer {
    margin-left: 0 !important;
    margin-bottom: 5rem !important;
  }
}/*# sourceMappingURL=CurrentCreditBody.css.map */