@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@1,700&display=swap");
/*Colors*/
/*Colors Transparency*/
/*Hover Colors*/
/*Button Color Effect*/
/*Styles*/
* {
  margin: 0;
}

/*Responsive - breakpoints*/
.stp-table-info-container {
  width: 100%;
  padding: 1rem 0;
  color: #014da1;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}
.stp-table-info-container .stp-title {
  width: 100%;
  text-align: center;
  font-size: 1.5rem;
  font-weight: 500;
}
.stp-table-info-container .stp-table-container {
  display: grid;
  grid-template-columns: 40% 60%;
  margin: 0 20%;
  -moz-column-gap: 1rem;
       column-gap: 1rem;
}
.stp-table-info-container .stp-table-container .stp-table-elm {
  border: 1px solid #014da1;
  width: 45.5rem;
  border-radius: 0.5rem;
  margin-left: -15%;
}
.stp-table-info-container .stp-table-container .stp-table-row-header {
  border-bottom: 1px solid #014da1;
  text-align: center;
  padding: 0.5rem 0;
}
.stp-table-info-container .stp-table-container .stp-table-column-header {
  border-right: 1px solid #014da1;
  padding: 0.5rem 0;
  border-bottom: 1px solid #014da1;
}
.stp-table-info-container .stp-table-container .last {
  border-right: none;
}
.stp-table-info-container .stp-table-container .stp-table-row-content {
  text-align: center;
}
.stp-table-info-container .stp-table-container .last-row {
  border-bottom: none;
}

@media screen and (min-width: 320px) and (max-width: 479px) {
  .stp-table-container {
    overflow-y: scroll;
    width: 80vw;
    padding-left: 1.5rem !important;
    margin: 0 5% !important;
  }
}
@media screen and (min-width: 480px) and (max-width: 767px) {
  .stp-table-container {
    overflow-y: scroll;
    width: 80vw;
    padding-left: 1.5rem !important;
    margin: 0 5% !important;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .stp-table-container {
    margin: 0 8% !important;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1199px) {
  .stp-table-container {
    margin: 0 6% !important;
  }
}
@media screen and (min-width: 1200px) and (max-width: 1919px) {
  .stp-table-container {
    margin: 0 12% !important;
  }
}/*# sourceMappingURL=SimulatorTablePreview.css.map */