@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@1,700&display=swap");
/*Colors*/
/*Colors Transparency*/
/*Hover Colors*/
/*Button Color Effect*/
/*Styles*/
* {
  margin: 0;
}

/*Responsive - breakpoints*/
.create-credit-renovation-section {
  width: 100%;
  display: flex;
  padding-top: 4rem;
}
.create-credit-renovation-section .create-credit-renovation-subcontainer {
  width: 1024px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 5rem 1rem 0 27%;
  row-gap: 4rem;
}
.create-credit-renovation-section .create-credit-renovation-subcontainer .form-with-steps-supercontainer {
  z-index: 1;
  width: 30rem;
}
.create-credit-renovation-section .create-credit-renovation-subcontainer .profile-deco {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 45%;
  z-index: 0;
}

@media screen and (min-width: 320px) and (max-width: 479px) {
  .create-credit-renovation-subcontainer {
    margin: 7rem 0 4rem 0 !important;
  }
  .create-credit-renovation-subcontainer .form-with-steps-supercontainer {
    width: 99% !important;
  }
  .create-credit-renovation-subcontainer .profile-deco {
    display: none;
  }
}
@media screen and (min-width: 480px) and (max-width: 767px) {
  .create-credit-renovation-subcontainer {
    margin: 7rem 0 4rem 0 !important;
    align-items: center;
  }
  .create-credit-renovation-subcontainer .form-with-steps-supercontainer {
    width: 29rem !important;
  }
  .create-credit-renovation-subcontainer .profile-deco {
    display: none;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .create-credit-renovation-subcontainer {
    margin: 12rem 0 4rem 0 !important;
    align-items: center;
  }
  .create-credit-renovation-subcontainer .form-with-steps-supercontainer {
    width: 29rem !important;
  }
  .create-credit-renovation-subcontainer .profile-deco {
    display: none;
  }
}
@media screen and (min-width: 1200px) and (max-width: 1919px) {
  .create-credit-renovation-subcontainer {
    margin-left: 15% !important;
    margin-bottom: 5rem !important;
  }
}
@media screen and (min-width: 1920px) {
  .create-credit-renovation-section {
    justify-content: center;
  }
  .create-credit-renovation-section .create-credit-renovation-subcontainer {
    margin-left: 0 !important;
    margin-bottom: 5rem !important;
  }
}/*# sourceMappingURL=CreateCreditRenovationBody.css.map */