@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@1,700&display=swap");
/*Colors*/
/*Colors Transparency*/
/*Hover Colors*/
/*Button Color Effect*/
/*Styles*/
* {
  margin: 0;
}

/*Responsive - breakpoints*/
.dashboard-section {
  width: 100%;
  display: flex;
  padding-top: 4rem;
}
.dashboard-section .dashboard-subcontainer {
  width: 1024px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 5rem 1rem 0 30%;
  row-gap: 4rem;
}
.dashboard-section .dashboard-subcontainer .dashboard-title {
  font-size: 1.7rem;
  color: #014da1;
  font-weight: 500;
}
.dashboard-section .dashboard-subcontainer .dashboard-subtitle {
  color: #014da1;
}
.dashboard-section .dashboard-subcontainer .filters-container {
  display: flex;
  -moz-column-gap: 1rem;
       column-gap: 1rem;
}
.dashboard-section .dashboard-subcontainer .filter-select {
  padding: 0.25rem 1.5rem;
  border: 1px solid #868686;
  border-radius: 0.25rem;
  color: #868686;
  outline: none;
}
.dashboard-section .dashboard-subcontainer .filter-btn {
  background-color: #014da1;
  border: 1px solid #014da1;
  color: #ffffff;
  padding: 0 2rem;
  cursor: pointer;
  border-radius: 0.25rem;
}
.dashboard-section .dashboard-subcontainer .filter-btn:hover {
  background-color: #ffffff;
  color: #014da1;
}
.dashboard-section .dashboard-subcontainer .first-half-container {
  display: grid;
  grid-template-columns: 50% 50%;
  border-bottom: 1px solid #014da1;
  padding-bottom: 4rem;
  -moz-column-gap: 1rem;
       column-gap: 1rem;
}
.dashboard-section .dashboard-subcontainer .second-half-container {
  display: grid;
  grid-template-columns: 40% 60%;
  -moz-column-gap: 1rem;
       column-gap: 1rem;
}

@media screen and (min-width: 320px) and (max-width: 479px) {
  .dashboard-section {
    padding-top: 9rem;
    padding-bottom: 5rem;
  }
  .dashboard-section .dashboard-subcontainer {
    margin: 0 1rem !important;
    row-gap: 2rem;
  }
  .dashboard-section .dashboard-subcontainer .first-half-container {
    grid-template-columns: 1fr !important;
    row-gap: 2rem;
    border-bottom: unset;
    padding-bottom: unset;
  }
  .dashboard-section .dashboard-subcontainer .second-half-container {
    grid-template-columns: 1fr !important;
    row-gap: 2rem;
  }
}
@media screen and (min-width: 480px) and (max-width: 767px) {
  .dashboard-section {
    padding-top: 9rem;
    padding-bottom: 5rem;
  }
  .dashboard-section .dashboard-subcontainer {
    margin: 0 1rem !important;
    row-gap: 2rem;
  }
  .dashboard-section .dashboard-subcontainer .filters-container {
    justify-content: center;
  }
  .dashboard-section .dashboard-subcontainer .first-half-container {
    grid-template-columns: 1fr !important;
    row-gap: 2rem;
    border-bottom: unset;
    padding-bottom: unset;
  }
  .dashboard-section .dashboard-subcontainer .second-half-container {
    grid-template-columns: 1fr !important;
    row-gap: 2rem;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .dashboard-section {
    padding-top: 12rem;
    padding-bottom: 5rem;
  }
  .dashboard-section .dashboard-subcontainer {
    margin: 0 1rem !important;
    row-gap: 2rem;
  }
  .dashboard-section .dashboard-subcontainer .filters-container {
    justify-content: center;
  }
  .dashboard-section .dashboard-subcontainer .first-half-container {
    row-gap: 2rem;
    border-bottom: unset;
    padding-bottom: unset;
  }
  .dashboard-section .dashboard-subcontainer .second-half-container {
    row-gap: 2rem;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1199px) {
  .dashboard-subcontainer {
    margin: 5rem 1rem 3rem 30% !important;
  }
}
@media screen and (min-width: 1200px) and (max-width: 1919px) {
  .dashboard-subcontainer {
    margin-left: 15% !important;
    margin-bottom: 5rem !important;
  }
  .dashboard-subcontainer .sell-insurer-graph-container {
    border-left: 1px solid #014da1;
    padding-left: 1rem;
  }
  .dashboard-subcontainer .bar-graph-supercontainer {
    border-left: 1px solid #014da1;
    padding-left: 1rem;
  }
}
@media screen and (min-width: 1920px) {
  .dashboard-section {
    justify-content: center;
  }
  .dashboard-subcontainer {
    margin-left: 0 !important;
    margin-bottom: 5rem !important;
  }
  .dashboard-subcontainer .first-half-container {
    -moz-column-gap: 3rem !important;
         column-gap: 3rem !important;
  }
  .dashboard-subcontainer .second-half-container {
    -moz-column-gap: 3rem !important;
         column-gap: 3rem !important;
  }
  .dashboard-subcontainer .sell-insurer-graph-container {
    border-left: 1px solid #014da1;
    padding-left: 3rem;
  }
  .dashboard-subcontainer .bar-graph-supercontainer {
    border-left: 1px solid #014da1;
    padding-left: 3rem;
  }
}/*# sourceMappingURL=DashboardBody.css.map */