@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@1,700&display=swap");
/*Colors*/
/*Colors Transparency*/
/*Hover Colors*/
/*Button Color Effect*/
/*Styles*/
* {
  margin: 0;
}

/*Responsive - breakpoints*/
.navbar-mobile-container {
  display: flex;
  flex-direction: column;
  background-color: #014da1;
  width: 100%;
  position: fixed;
  z-index: 99;
}
.navbar-mobile-container .nbm-first-half-container {
  display: flex;
  align-items: center;
  width: 100%;
  -moz-column-gap: 1rem;
       column-gap: 1rem;
  font-size: 0.9rem;
  color: #ffffff;
  justify-content: space-between;
  height: 3rem;
  border-bottom: 1px solid #ffffff;
  padding-bottom: 0.25rem;
}
.navbar-mobile-container .nbm-first-half-container .logo-img-container {
  width: 9rem;
  margin: 1rem 0 0 1rem;
}
.navbar-mobile-container .nbm-first-half-container .logo-img {
  width: 100%;
}
.navbar-mobile-container .nbm-first-half-container .nbm-greetings {
  text-align: center;
  font-size: 0.8rem;
  margin-right: 1rem;
}
.navbar-mobile-container .nbm-admin-mode-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  -moz-column-gap: 1rem;
       column-gap: 1rem;
}
.navbar-mobile-container .nbm-admin-mode-container .nb-sa-select {
  border: 1px solid #868686;
  border-radius: 0.25rem;
  color: #868686;
  outline: none;
}
.navbar-mobile-container .nbm-admin-mode-container .nb-sa-btn {
  background-color: #ffffff;
  border: 1px solid #ffffff;
  color: #014da1;
  padding: 0.25rem;
  cursor: pointer;
  border-radius: 0.25rem;
  height: 2.4rem;
}
.navbar-mobile-container .nbm-admin-mode-container .nb-sa-btn:hover {
  background-color: #014da1;
  color: #ffffff;
}
.navbar-mobile-container .nbm-admin-mode-container .logged {
  height: auto;
}
.navbar-mobile-container .nbm-second-half-container {
  margin: 0 1rem;
  padding-top: 0.25rem;
  overflow-x: scroll;
}
.navbar-mobile-container .nbm-second-half-container .menus-container {
  display: flex;
  align-items: center;
  row-gap: 2rem;
  position: relative;
  width: 100%;
}
.navbar-mobile-container .nbm-second-half-container .option-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  width: 95.5%;
  row-gap: 0.5rem;
}
.navbar-mobile-container .nbm-second-half-container .option-container:link {
  color: #ffffff;
  text-decoration: none;
}
.navbar-mobile-container .nbm-second-half-container .option-container:visited {
  color: #ffffff;
}
.navbar-mobile-container .nbm-second-half-container .option-container:active {
  color: #ffffff;
}
.navbar-mobile-container .nbm-second-half-container .current-select {
  border: 1px solid #ffffff;
  padding: 0.5rem 0;
  background-color: #ffffff;
  color: #014da1 !important;
  border-radius: 200px 200px 0px 0px;
  -webkit-border-radius: 200px 200px 0px 0px;
  -moz-border-radius: 200px 200px 0px 0px;
}
.navbar-mobile-container .nbm-second-half-container .option-icon {
  width: 1.5rem;
}
.navbar-mobile-container .nbm-second-half-container .title-option {
  text-align: center;
  font-weight: bold;
  font-size: 0.5rem;
}

@media screen and (min-width: 1024px) {
  .navbar-mobile-container {
    display: none;
  }
}/*# sourceMappingURL=NavbarMobile.css.map */