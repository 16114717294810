@import "../../sass/variables";

.login-screen-container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  overflow: hidden;
  background-color: $mainColorPrimary;
  .login-screen-bg-img {
    height: auto;
    width: 1920px;
    object-fit: cover;
  }
  .login-screen-deco-img {
    position: absolute;
    right: 0;
    bottom: 0;
    height: 95vh;
    width: auto;
  }
  .login-screen-subcontainer {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    row-gap: 1rem;
    position: absolute;
    .login-txt-container {
      text-align: center;
      color: $basicColorPrimary;
      display: flex;
      flex-direction: column;
      row-gap: 1.5rem;
    }
    .login-form-supercontainer {
      width: 30rem;
    }
    .login-logo {
      width: 20rem;
      height: auto;
    }
  }
}

//Responsive Mobile Small
@media screen and (min-width: $mobileSm) and (max-width: calc($mobileStandard - 1px)) {
  .login-screen-deco-img {
    display: none !important;
  }
  .login-logo {
    width: 12rem !important;
  }
  .login-form-supercontainer {
    width: 90% !important;
  }
}

//Responsive Mobile Standard
@media screen and (min-width: $mobileStandard) and (max-width: calc($tabletSm - 1px)) {
  .login-screen-deco-img {
    display: none !important;
  }
  .login-logo {
    width: 20rem !important;
  }
  .login-form-supercontainer {
    width: 90% !important;
  }
}

//Responsive Tablet Small
@media screen and (min-width: $tabletSm) and (max-width: calc($tabletStandard - 1px)) {
  .login-screen-deco-img {
    display: none !important;
  }
  .login-logo {
    width: 20rem !important;
  }
  .login-form-supercontainer {
    width: 60% !important;
  }
}

//Responsive Tablet Standard
@media screen and (min-width: $tabletStandard) and (max-width: calc($screenSm - 1px)) {
  .login-screen-deco-img {
    display: none !important;
  }
}
