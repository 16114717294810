@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@1,700&display=swap");
/*Colors*/
/*Colors Transparency*/
/*Hover Colors*/
/*Button Color Effect*/
/*Styles*/
* {
  margin: 0;
}

/*Responsive - breakpoints*/
.bg-title {
  font-size: 1.3rem;
  color: #868686;
  font-weight: 500;
  margin-bottom: 1rem;
}

.other-description-container {
  font-size: 0.8rem;
  color: #014da1;
}

.scroll-container {
  display: flex;
  overflow-x: auto;
  width: 23rem;
  height: 200px;
  scroll-snap-type: x mandatory;
}
.scroll-container::-webkit-scrollbar {
  height: 0.5rem;
  background-color: #014da1;
  border-radius: 1rem;
}
.scroll-container::-webkit-scrollbar-thumb {
  height: 0.5rem;
  background-color: #42ffff;
  border-radius: 1rem;
}
.scroll-container .scroll-page {
  flex: 0 0 auto;
  width: 100%;
  scroll-snap-align: start;
}
.scroll-container .scroll-page.focused {
  background-color: #014da1;
  border-color: #868686;
}

@media screen and (min-width: 320px) and (max-width: 479px) {
  .scroll-container {
    width: 90vw;
  }
}
@media screen and (min-width: 480px) and (max-width: 767px) {
  .bar-graph-container {
    margin: 0 10%;
  }
}
@media screen and (min-width: 1920px) {
  .scroll-container {
    width: 85%;
    height: 15rem;
  }
}/*# sourceMappingURL=BarGraphComponent.css.map */