@import "../../sass/variables";

.credit-detail-table-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  transform: rotateX(180deg);
  overflow-x: auto;
  .cdt-download-docs-container {
    width: 28.75%;
    align-self: flex-end;
    .cdt-download-docs-subcontainer {
      background-color: $mainColorPrimary;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding: 1rem 1rem;
      row-gap: 1rem;
    }
    .cdt-dd-title {
      font-size: 1rem;
      color: $basicColorPrimary;
      font-weight: 300;
    }
    .cdt-dd-form {
      width: 100%;
      display: flex;
      flex-direction: column;
      row-gap: 1rem;
    }
    .cdt-dd-btns-container {
      width: 100%;
      display: flex;
      justify-content: space-between;
      column-gap: 1rem;
    }
    .cdt-dd-btns {
      padding: 0.55rem 1rem;
      background-color: $scrollBarColorPrimary;
      color: $mainColorPrimary;
      font-weight: bold;
      border: 1px solid $scrollBarColorPrimary;
      cursor: pointer;
      border-radius: 0.5rem;
      height: 100%;
      width: 100%;
      &:hover {
        background-color: $basicColorPrimary;
        border-color: $scrollBarColorPrimary;
        color: $mainColorPrimary;
      }
    }
  }
  .cdt-table-container {
    border-collapse: collapse;
    
    transform: rotateX(180deg);
    .cdt-table-row {
      color: $mainColorPrimary;
      border-bottom: 1px solid $mainColorPrimary;
    }
    .cdt-table-header-title {
      padding: 0.5rem 1rem;
      align-items: center;
      border-bottom: 1px solid $mainColorPrimary;
    }
    .actions-btn {
      display: flex;
      border-bottom: none;
      column-gap: 0.5rem;
    }
    .centered {
      min-height: 8rem;
      display: flex;
      justify-content: center;
      border-bottom: none;
    }
    .left-border {
      border-left: 1px solid $mainColorPrimary;
    }
    .right-border {
      border-right: 1px solid $mainColorPrimary;
    }
    .checkbox-input {
      width: 1.2rem;
      height: 1.2rem;
    }
    .cdt-table-checkbox-block {
      width: 1.5rem;
      height: 1.5rem;
    }
    .cdt-tht-action-btn {
      border: 1px solid $mainColorPrimary;
      background-color: $mainColorPrimary;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0.5rem;
      border-radius: 100%;
      cursor: pointer;
      color: $basicColorPrimary;
      &:hover {
        color: $mainColorPrimary;
        background-color: $basicColorPrimary;
      }
      .cdt-tht-action-btn-icon {
        width: auto;
        height: 1rem;
      }
    }
    .danger {
      background-color: $alertBtnColorPrimary;
      border-color: $alertBtnColorPrimary;
    }
    .danger-txt {
      color: $alertBtnColorPrimary;
    }
    .has_text_radius {
      border-radius: 1rem;
      min-width: 8rem;
    }
  }
}
.cdt-pagination-elms {
  padding-top: 1rem;
  .pagination-btns-container {
    display: flex;
    align-items: center;
    column-gap: 2rem;
  }
  .pagination-btns {
    display: flex;
    column-gap: 1rem;
    color: $barGraphListColorPrimary;
  }
  .pagination-btn {
    background-color: $mainColorPrimary;
    border: 1px solid $mainColorPrimary;
    color: $basicColorPrimary;
    border-radius: 0.25rem;
    cursor: pointer;
    font-weight: bold;
    font-size: 1.2rem;
    &:hover {
      background-color: $scrollBarColorPrimary;
      border-color: $scrollBarColorPrimary;
      color: $mainColorPrimary;
    }
  }
  .pagination-info {
    color: $barGraphListColorPrimary;
  }
  .select-max-data {
    width: 5rem !important;
  }
}

//Responsive Mobile Small
@media screen and (min-width: $mobileSm) and (max-width: calc($mobileStandard - 1px)) {
  .cdt-download-docs-subcontainer {
    position: relative;
    left: -13rem;
    width: 15rem;
  }
  .cdt-download-docs-container {
    width: 25.5% !important;
  }
}

//Responsive Mobile Standard
@media screen and (min-width: $mobileStandard) and (max-width: calc($tabletSm - 1px)) {
  .cdt-download-docs-subcontainer {
    position: relative;
    left: -13rem;
    width: 15rem;
  }
  .cdt-download-docs-container {
    width: 35% !important;
  }
}

//Responsive Tablet Small
@media screen and (min-width: $tabletSm) and (max-width: calc($tabletStandard - 1px)) {
  .cdt-download-docs-subcontainer {
    position: relative;
    left: -13rem;
    width: 15rem;
  }
  .cdt-download-docs-container {
    width: 35% !important;
  }
}

//Responsive Tablet Standard
@media screen and (min-width: $tabletStandard) and (max-width: calc($screenSm - 1px)) {
  .cdt-download-docs-subcontainer {
    position: relative;
    left: -13rem;
    width: 15rem;
  }
  .cdt-download-docs-container {
    width: 35% !important;
  }
}

//Responsive Screen Small
@media screen and (min-width: $screenSm) and (max-width: calc($screenHd - 1px)) {
  .cdt-download-docs-subcontainer {
    position: relative;
    left: 3.2rem;
    width: 12.9rem;
  }
  .cdt-download-docs-container {
    width: 31% !important;
  }
}
