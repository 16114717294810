@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@1,700&display=swap");
/*Colors*/
/*Colors Transparency*/
/*Hover Colors*/
/*Button Color Effect*/
/*Styles*/
* {
  margin: 0;
}

/*Responsive - breakpoints*/
.payment-method-section {
  width: 100%;
  display: flex;
  padding-top: 0;
}
.payment-method-section .payment-method-subcontainer {
  width: 1024px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 7rem 1rem 0 27%;
  row-gap: 4rem;
}
.payment-method-section .payment-method-subcontainer .pm-info-container {
  max-width: 32rem;
  background-color: #ffffff;
  border: 1px solid #014da1;
  border-radius: 1rem;
  box-shadow: 10px 10px 25px -8px rgba(0, 0, 0, 0.4);
  -webkit-box-shadow: 10px 10px 25px -8px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 10px 10px 25px -8px rgba(0, 0, 0, 0.4);
}
.payment-method-section .payment-method-subcontainer .pm-info-subcontainer {
  margin: 2rem 1.5rem;
  color: #014da1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 1.5rem;
}
.payment-method-section .payment-method-subcontainer .pm-info-img {
  width: 10rem;
  height: auto;
}
.payment-method-section .payment-method-subcontainer .pm-info-content-container {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}
.payment-method-section .payment-method-subcontainer .pm-ic-title {
  font-size: 1.1rem;
}
.payment-method-section .payment-method-subcontainer .pm-ic-elms-container {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}
.payment-method-section .payment-method-subcontainer .pm-ic-e-title {
  font-size: 1rem;
}
.payment-method-section .payment-method-subcontainer .pm-ic-list-container {
  font-size: 0.9rem;
}
.payment-method-section .payment-method-subcontainer .pm-ic-advice-content-container {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  font-size: 0.8rem;
}
.payment-method-section .payment-method-subcontainer .pm-ic-se-le-anchor {
  color: #014da1;
  font-weight: bold;
}
.payment-method-section .payment-method-subcontainer .pm-deco {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 45%;
  z-index: -1;
}

@media screen and (min-width: 320px) and (max-width: 479px) {
  .payment-method-subcontainer {
    margin: 10rem 0 4rem 0 !important;
    width: 100% !important;
  }
  .payment-method-subcontainer .pm-deco {
    display: none;
  }
}
@media screen and (min-width: 480px) and (max-width: 767px) {
  .payment-method-subcontainer {
    margin: 10rem 0 4rem 0 !important;
    width: 100% !important;
    align-items: center;
  }
  .payment-method-subcontainer .pm-deco {
    display: none;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .payment-method-subcontainer {
    margin: 10rem 0 4rem 15% !important;
  }
  .payment-method-subcontainer .pm-deco {
    display: none;
  }
}
@media screen and (min-width: 1200px) and (max-width: 1919px) {
  .payment-method-subcontainer {
    margin-left: 15% !important;
    margin-bottom: 5rem !important;
  }
}
@media screen and (min-width: 1920px) {
  .payment-method-section {
    justify-content: center;
  }
  .payment-method-section .payment-method-subcontainer {
    margin-left: 0 !important;
  }
}/*# sourceMappingURL=PaymentMethodBody.css.map */