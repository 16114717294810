@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@1,700&display=swap");
/*Colors*/
/*Colors Transparency*/
/*Hover Colors*/
/*Button Color Effect*/
/*Styles*/
* {
  margin: 0;
}

/*Responsive - breakpoints*/
.survey-form-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #ffffff;
  border: 1px solid #014da1;
  border-radius: 0.5rem;
  position: relative;
  box-shadow: 10px 6px 18px -8px rgba(0, 0, 0, 0.67);
  row-gap: 1rem;
  margin-bottom: 1.5rem;
}
.survey-form-container .sf-header-title-container {
  background-color: #014da1;
  border: 1px solid #014da1;
  border-top-right-radius: 0.5rem;
  border-top-left-radius: 0.5rem;
  color: #ffffff;
  padding: 1rem 3rem;
  text-align: center;
  font-size: 0.9rem;
}
.survey-form-container .sf-subtitle-container {
  font-size: 0.9rem;
  padding: 0.25rem 2rem;
  color: #014da1;
}
.survey-form-container .sf-form-container {
  padding: 0 2rem 2rem 2rem;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}
.survey-form-container .sf-form-container .choice-input-container {
  display: flex;
  -moz-column-gap: 1rem;
       column-gap: 1rem;
  margin-top: 0.5rem;
}
.survey-form-container .sf-form-container .current-step {
  display: grid;
  align-items: flex-end;
  grid-template-columns: 1fr;
  -moz-column-gap: 1rem;
       column-gap: 1rem;
  row-gap: 1rem;
}
.survey-form-container .sf-form-container label {
  display: flex;
  flex-direction: column;
  color: #014da1;
  font-style: italic;
}
.survey-form-container .sf-form-container input {
  margin-top: 0.5rem;
  padding: 0.5rem 0;
  padding-left: 1rem;
  border-radius: 0.5rem !important;
  border: 2px solid #014da1 !important;
  width: 90%;
  font-family: "Roboto", sans-serif;
  cursor: pointer;
}
.survey-form-container .sf-form-container select {
  margin-top: 0.5rem;
  padding: 0.5rem 0;
  padding-left: 1rem;
  border-radius: 0.5rem !important;
  border: 2px solid #014da1 !important;
  width: 100%;
  font-family: "Roboto", sans-serif;
}
.survey-form-container .sf-form-container .sf-likert {
  display: flex;
  justify-content: space-between;
  list-style: none;
  padding: 0;
}
.survey-form-container .sf-form-container .mandatory-text {
  font-size: 0.8rem;
}
.survey-form-container .sf-form-container .hide {
  opacity: 0;
}
.survey-form-container .sf-form-container .fws-btns-container {
  display: flex;
  -moz-column-gap: 1rem;
       column-gap: 1rem;
}
.survey-form-container .sf-form-container .fws-btn {
  width: 100%;
  height: 2.5rem;
  background-color: #42ffff;
  color: #014da1;
  outline: none;
  border: 1px solid #42ffff;
  cursor: pointer;
  border-radius: 0.5rem;
  font-weight: bold;
}
.survey-form-container .sf-form-container .fws-btn:hover {
  background-color: #014da1;
  color: #ffffff;
  border-color: #014da1;
}

@media screen and (min-width: 320px) and (max-width: 479px) {
  .current-step {
    grid-template-columns: unset !important;
  }
  input {
    width: unset !important;
  }
}/*# sourceMappingURL=SurveyFormComponent.css.map */