@import "../../sass/variables";

.profile-form-container {
  width: 20rem;
  border: 1px solid $mainColorPrimary;
  border-radius: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3rem 2rem;
  box-shadow: 10px 10px 25px -8px rgba(0, 0, 0, 0.4);
  -webkit-box-shadow: 10px 10px 25px -8px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 10px 10px 25px -8px rgba(0, 0, 0, 0.4);
  background-color: $basicColorPrimary;
  .pf-subcontainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 2rem;
    .pf-logo {
      width: 13rem;
    }
    .pf-inputs-container {
      display: flex;
      flex-direction: column;
      row-gap: 1rem;
      width: 100%;
      align-items: center;
    }
    .label {
      width: 100%;
      background-color: $basicColorPrimary;
      border: 1px solid darken($nbBackgroundColor, $amount: 10);
      border-radius: 0.2rem;
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
    .border-err {
      border-color: $alertBtnColorPrimary;
    }
    .password-advice {
      color: $mainColorPrimary;
      font-size: 0.8rem;
      display: none;
    }
    .pa-show {
      display: unset;
    }
    .input-icon {
        width: 1rem;
        height: auto;
        margin-left: 1rem;
    }
    .phone-fix {
      height: 1.1rem;
    }
    .edit-icon {
        width: 1rem;
        height: 1rem;
        margin-right: 0.5rem;
        cursor: pointer;
    }
    input {
      width: 80%;
      padding: 0.5rem 0 0.5rem 1rem;
      outline: none;
      background-color: $basicColorPrimary;
      border: 1px solid $basicColorPrimary;
    }
    .pf-btn-container {
        width: 100%;
        display: flex;
        justify-content: space-between;
        column-gap: 2rem;
        margin-top: 1rem;
    }
    .pf-btn {
        width: 100%;
        padding: 0.5rem;
        background-color: $scrollBarColorPrimary;
        color: $mainColorPrimary;
        border: 1px solid $scrollBarColorPrimary;
        border-radius: 0.25rem;
        display: flex;
        align-items: center;
        justify-content: center;
        outline: none;
        cursor: pointer;
        &:hover {
            background-color: $mainColorPrimary;
            border-color: $mainColorPrimary;
            color: $basicColorPrimary;
        }
    }
    .cancel {
        background-color: $alertBtnColorPrimary;
        border-color: $alertBtnColorPrimary;
        color: $basicColorPrimary;
        &:hover {
            background-color: $basicColorPrimary;
            border-color: $alertBtnColorPrimary;
            color: $alertBtnColorPrimary;
        }
    }
  }
}

//Responsive Mobile Small
@media screen and (min-width: $mobileSm) and (max-width: calc($mobileStandard - 1px)) {
  .profile-form-container {
    width: 79% !important;
    input {
      width: 100% !important;
    }
  }
}