@import "../../sass/variables";

.notification-container {
  position: absolute;
  width: 23rem;
  height: 16.8rem;
  background-color: $basicColorPrimary;
  overflow-y: scroll;
  z-index: 99;
  top: -100vh;
  right: 14rem;
  overflow-x: hidden;
  border-radius: 0 0 0 1rem;
  &::-webkit-scrollbar {
    width: 0.5rem;
    background-color: $basicColorPrimary;
    border-radius: 1rem;
  }
  &::-webkit-scrollbar-thumb {
    width: 0.5rem;
    background-color: $basicColorPrimary;
    border-radius: 1rem;
  }
}
.show-noti {
  @keyframes showNoti {
    0% {
      top: -100vh;
    }
    100% {
      top: 5rem;
    }
  }
  animation-name: showNoti;
  animation-duration: 1s;
  top: 5rem;
}

.hide-noti {
  @keyframes hideNoti {
    0% {
      top: 5rem;
    }
    100% {
      top: -100vh;
    }
  }
  animation-name: hideNoti;
  animation-duration: 2s;
  top: -100vh;
}
.navbar-container {
  width: 100%;
  height: 4rem;
  background-color: $nbBackgroundColor;
  position: fixed;
  display: flex;
  justify-content: flex-end;
  z-index: 99;
  .elms-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    width: 70%;
    column-gap: 1rem;
    .nb-superadmin-menu {
      display: grid;
      grid-template-columns: 60% 40%;
      column-gap: 1rem;
      min-width: 20rem;
    }
    .nb-sa-select {
      border: 1px solid $barGraphListColorPrimary;
      border-radius: 0.25rem;
      color: $barGraphListColorPrimary;
      outline: none;
    }
    .nb-sa-btn {
      background-color: $mainColorPrimary;
      border: 1px solid $mainColorPrimary;
      color: $basicColorPrimary;
      padding: 0.25rem;
      cursor: pointer;
      border-radius: 0.25rem;
      &:hover {
        background-color: $basicColorPrimary;
        color: $mainColorPrimary;
      }
    }
    .nb-menus-container {
      display: flex;
      align-items: center;
      column-gap: 1rem;
      margin-right: 5%;
    }
    .profile-text-container {
      color: $barGraphListColorPrimary;
    }
    .menu-container {
      display: flex;
      align-items: center;
      column-gap: 0.5rem;
      cursor: pointer;
    }
    .line {
      border-left: 1px solid $mainColorPrimary;
      padding-left: 0.5rem;
    }
    .menu-icon {
      height: 1.5rem;
      width: auto;
    }
    h1 {
      font-size: 0.8rem;
    }
  }
}

//Responsive Mobile Small
@media screen and (min-width: $mobileSm) and (max-width: calc($mobileStandard - 1px)) {
  .notification-container {
    display: none;
  }
  .navbar-container {
    display: none;
  }
}

//Responsive Mobile Standard
@media screen and (min-width: $mobileStandard) and (max-width: calc($tabletSm - 1px)) {
  .notification-container {
    display: none;
  }
  .navbar-container {
    display: none;
  }
}

//Responsive Tablet Small
@media screen and (min-width: $tabletSm) and (max-width: calc($tabletStandard - 1px)) {
  .notification-container {
    display: none;
  }
  .navbar-container {
    display: none;
  }
}

//Responsive Screen Small
@media screen and (min-width: $screenSm) and (max-width: calc($screenHd - 1px)) {
  .navbar-container {
    justify-content: space-around;
  }
}

//Responsive HD Screen
@media screen and (min-width: $screenHd) {
  .navbar-container {
    justify-content: center;
  }
}
