@import "../../sass/variables";

.dashboard-section {
  width: 100%;
  display: flex;
  padding-top: 4rem;
  .dashboard-subcontainer {
    width: 1024px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: 5rem 1rem 0 30%;
    row-gap: 4rem;
    .dashboard-title {
      font-size: 1.7rem;
      color: $mainColorPrimary;
      font-weight: 500;
    }
    .dashboard-subtitle {
      color: $mainColorPrimary;
    }
    .filters-container {
      display: flex;
      column-gap: 1rem;
    }
    .filter-select {
      padding: 0.25rem 1.5rem;
      border: 1px solid $barGraphListColorPrimary;
      border-radius: 0.25rem;
      color: $barGraphListColorPrimary;
      outline: none;
    }
    .filter-btn {
      background-color: $mainColorPrimary;
      border: 1px solid $mainColorPrimary;
      color: $basicColorPrimary;
      padding: 0 2rem;
      cursor: pointer;
      border-radius: 0.25rem;
      &:hover {
        background-color: $basicColorPrimary;
        color: $mainColorPrimary;
      }
    }
    .first-half-container {
      display: grid;
      grid-template-columns: 50% 50%;
      border-bottom: 1px solid $mainColorPrimary;
      padding-bottom: 4rem;
      column-gap: 1rem;
    }
    .second-half-container {
      display: grid;
      grid-template-columns: 40% 60%;
      column-gap: 1rem;
    }
  }
}

//Responsive Mobile Small
@media screen and (min-width: $mobileSm) and (max-width: calc($mobileStandard - 1px)) {
  .dashboard-section {
    padding-top: 9rem;
    padding-bottom: 5rem;
    .dashboard-subcontainer {
      margin: 0 1rem !important;
      row-gap: 2rem;
      .first-half-container {
        grid-template-columns: 1fr !important;
        row-gap: 2rem;
        border-bottom: unset;
        padding-bottom: unset;
      }
      .second-half-container {
        grid-template-columns: 1fr !important;
        row-gap: 2rem;
      }
    }
  }
}

//Responsive Mobile Standard
@media screen and (min-width: $mobileStandard) and (max-width: calc($tabletSm - 1px)) {
  .dashboard-section {
    padding-top: 9rem;
    padding-bottom: 5rem;
    .dashboard-subcontainer {
      margin: 0 1rem !important;
      row-gap: 2rem;
      .filters-container {
        justify-content: center;
      }
      .first-half-container {
        grid-template-columns: 1fr !important;
        row-gap: 2rem;
        border-bottom: unset;
        padding-bottom: unset;
      }
      .second-half-container {
        grid-template-columns: 1fr !important;
        row-gap: 2rem;
      }
    }
  }
}

//Responsive Tablet Small
@media screen and (min-width: $tabletSm) and (max-width: calc($tabletStandard - 1px)) {
  .dashboard-section {
    padding-top: 12rem;
    padding-bottom: 5rem;
    .dashboard-subcontainer {
      margin: 0 1rem !important;
      row-gap: 2rem;
      .filters-container {
        justify-content: center;
      }
      .first-half-container {
        row-gap: 2rem;
        border-bottom: unset;
        padding-bottom: unset;
      }
      .second-half-container {
        row-gap: 2rem;
      }
    }
  }
}

//Responsive Tablet Standard
@media screen and (min-width: $tabletStandard) and (max-width: calc($screenSm - 1px)) {
  .dashboard-subcontainer {
    margin: 5rem 1rem 3rem 30% !important;
  }
}

//Responsive Small Screen
@media screen and (min-width: $screenSm) and (max-width: calc($screenHd - 1px)) {
  .dashboard-subcontainer {
    margin-left: 15% !important;
    margin-bottom: 5rem !important;
    .sell-insurer-graph-container {
      border-left: 1px solid $mainColorPrimary;
      padding-left: 1rem;
    }
    .bar-graph-supercontainer {
      border-left: 1px solid $mainColorPrimary;
      padding-left: 1rem;
    }
  }
}

//Responsive HD Screen
@media screen and (min-width: $screenHd) {
  .dashboard-section {
    justify-content: center;
  }
  .dashboard-subcontainer {
    margin-left: 0 !important;
    margin-bottom: 5rem !important;
    .first-half-container {
      column-gap: 3rem !important;
    }
    .second-half-container {
      column-gap: 3rem !important;
    }
    .sell-insurer-graph-container {
      border-left: 1px solid $mainColorPrimary;
      padding-left: 3rem;
    }
    .bar-graph-supercontainer {
      border-left: 1px solid $mainColorPrimary;
      padding-left: 3rem;
    }
  }
}
