@import "../../sass/variables";

.certification-section {
  width: 100%;
  display: flex;
  padding-top: 4rem;
  .certification-subcontainer {
    width: 1024px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: 5rem 0 0 27%;
    row-gap: 2.5rem;
    .c-title {
      font-size: 1.8rem;
      font-weight: 500;
      color: $mainColorPrimary;
    }
    .cdt-txt {
      color: $mainColorPrimary;
    }
    .c-description-txt-container {
      display: flex;
      flex-direction: column;
      row-gap: 0.5rem;
    }
    .input-container {
      display: flex;
      column-gap: 1rem;
      align-items: flex-end;
    }
    .cip-de-btn {
      padding: 0.55rem 1rem;
      background-color: $mainColorPrimary;
      color: $basicColorPrimary;
      font-weight: bold;
      border: 1px solid $mainColorPrimary;
      cursor: pointer;
      border-radius: 0.5rem;
      height: 100%;
      width: 12rem;
      &:hover {
        background-color: $scrollBarColorPrimary;
        border-color: $scrollBarColorPrimary;
        color: $mainColorPrimary;
      }
    }
    .filter-form-container {
      display: grid;
      align-items: flex-end;
      column-gap: 1rem;
      grid-template-columns: 1fr 1fr;
    }
    label {
      display: flex;
      flex-direction: column;
      font-size: 0.9rem;
      row-gap: 0.5rem;
    }
    .label-title {
      font-size: 0.9rem;
      color: $mainColorPrimary;
      font-weight: 500;
    }
    input {
      padding: 0.5rem 1rem;
      outline: none;
      border: 1px solid $barGraphListColorPrimary;
      border-radius: 0.5rem;
      cursor: pointer;
      color: $barGraphListColorPrimary;
      font-weight: 500;
    }
    select {
      padding: 0.5rem 1rem;
      outline: none;
      border: 1px solid $barGraphListColorPrimary;
      color: $barGraphListColorPrimary;
      border-radius: 0.5rem;
      cursor: pointer;
      font-weight: 500;
    }
    .c-input-search {
      padding: 0.5rem 1rem;
      border: 1px solid $mainColorPrimary;
      color: $mainColorPrimary;
      background-color: $basicColorPrimary;
      outline: none;
      border-radius: 0.5rem;
      width: 50%;
      &::placeholder {
        color: $mainColorPrimary;
      }
    }
    .c-btn-search {
      padding: 0.55rem 1rem;
      background-color: $mainColorPrimary;
      color: $basicColorPrimary;
      font-weight: bold;
      border: 1px solid $mainColorPrimary;
      cursor: pointer;
      border-radius: 0.5rem;
      height: 100%;
      width: 12rem;
      &:hover {
        background-color: $scrollBarColorPrimary;
        border-color: $scrollBarColorPrimary;
        color: $mainColorPrimary;
      }
    }
  }
}

//Responsive Mobile Small
@media screen and (min-width: $mobileSm) and (max-width: calc($mobileStandard - 1px)) {
  .certification-subcontainer {
    margin: 7rem 0 4rem 0 !important;
    width: 100%;
    .filter-elms-container {
      margin: 0 1rem;
      .filter-form-container {
        grid-template-columns: 1fr !important;
        width: 100% !important;
      }
    }
    select {
      width: 90vw !important;
    }
    .input-container {
      flex-direction: column;
      row-gap: 1rem;
      align-items: center !important;
    }
    .docs-container {
      flex-direction: column;
    }
    .profile-deco {
      display: none;
    }
    .cd-logo {
      width: 7rem !important;
    }
    .credit-details-table-supercontainer {
      width: 90vw;
      overflow-x: scroll;
      margin-left: 1rem;
    }
  }
}

//Responsive Mobile Standard
@media screen and (min-width: $mobileStandard) and (max-width: calc($tabletSm - 1px)) {
  .certification-subcontainer {
    margin: 7rem 0 4rem 0 !important;
    align-items: center;
    .filter-elms-container {
      margin: 0 1rem;
      .filter-form-container {
        grid-template-columns: 50% 50% !important;
        width: 90vw;
      }
    }
    .input-container {
      flex-direction: column;
      row-gap: 1rem;
      align-items: center !important;
    }
    .c-elms-container {
      margin: 0 10%;
    }
    .docs-container {
      flex-direction: column;
    }
    .profile-deco {
      display: none;
    }
    .cd-logo {
      width: 7rem !important;
    }
    .credit-details-table-supercontainer {
      width: 90vw;
      overflow-x: scroll;
      margin-left: 1rem;
    }
  }
}

//Responsive Tablet Small
@media screen and (min-width: $tabletSm) and (max-width: calc($tabletStandard - 1px)) {
  .certification-subcontainer {
    margin: 12rem 0 4rem 0 !important;
    align-items: center;
    .filter-elms-container {
      margin: 0 1rem;
      .filter-form-container {
        grid-template-columns: 1fr 1fr !important;
      }
    }
    .input-container {
      flex-direction: column;
      row-gap: 1rem;
      align-items: center !important;
    }
    .credit-details-table-supercontainer {
      width: 90vw;
      overflow-x: scroll;
      margin-left: 1rem;
    }
  }
}

//Responsive Tablet Standard
@media screen and (min-width: $tabletStandard) and (max-width: calc($screenSm - 1px)) {
  .filter-elms-container {
    margin: 0 1rem;
    .filter-form-container {
      grid-template-columns: 1fr 1fr !important;
    }
  }
  .input-container {
    flex-direction: column;
    row-gap: 1rem;
    align-items: center !important;
  }
  .credit-details-table-supercontainer {
    width: 70vw;
    overflow-x: scroll;
    margin-left: 1rem;
  }
}

//Responsive Small Screen
@media screen and (min-width: $screenSm) and (max-width: calc($screenHd - 1px)) {
  .certification-subcontainer {
    margin-left: 15% !important;
    margin-bottom: 5rem !important;
    .filter-elms-container {
      margin: 0 1rem;
      .filter-form-container {
        grid-template-columns: 1fr 1fr !important;
      }
    }
    .input-container {
      flex-direction: column;
      row-gap: 1rem;
      align-items: center !important;
    }
    .credit-details-table-supercontainer {
      width: 70vw;
      overflow-x: scroll;
      margin-left: 1rem;
    }
  }
}

//Responsive HD Screen
@media screen and (min-width: $screenHd) {
  .certification-section {
    justify-content: center;
    .certification-subcontainer {
      margin-left: 0 !important;
      margin-bottom: 5rem !important;
      .profile-deco {
        right: -15%;
      }
    }
    select {
      width: 12rem !important;
    }
  }
}
