@import "../../sass/variables";

.navbar-mobile-container {
  display: flex;
  flex-direction: column;
  background-color: $mainColorPrimary;
  width: 100%;
  position: fixed;
  z-index: 99;
  .nbm-first-half-container {
    display: flex;
    align-items: center;
    width: 100%;
    column-gap: 1rem;
    font-size: 0.9rem;
    color: $basicColorPrimary;
    justify-content: space-between;
    height: 3rem;
    border-bottom: 1px solid $basicColorPrimary;
    padding-bottom: 0.25rem;
    .logo-img-container {
      width: 9rem;
      margin: 1rem 0 0 1rem;
    }
    .logo-img {
      width: 100%;
    }
    .nbm-greetings {
      text-align: center;
      font-size: 0.8rem;
      margin-right: 1rem;
    }
  }
  .nbm-admin-mode-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 1rem;
    .nb-sa-select {
      border: 1px solid $barGraphListColorPrimary;
      border-radius: 0.25rem;
      color: $barGraphListColorPrimary;
      outline: none;
    }
    .nb-sa-btn {
      background-color: $basicColorPrimary;
      border: 1px solid $basicColorPrimary;
      color: $mainColorPrimary;
      padding: 0.25rem;
      cursor: pointer;
      border-radius: 0.25rem;
      height: 2.4rem;
      &:hover {
        background-color: $mainColorPrimary;
        color: $basicColorPrimary;
      }
    }
    .logged {
      height: auto;
    }
  }
  .nbm-second-half-container {
    margin: 0 1rem;
    padding-top: 0.25rem;
    overflow-x: scroll;
    .menus-container {
      display: flex;
      align-items: center;
      row-gap: 2rem;
      position: relative;
      width: 100%; // 50rem para mostrar muchos menus
    }
    .option-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: $basicColorPrimary;
      width: 95.5%;
      row-gap: 0.5rem;
      &:link {
        color: $basicColorPrimary;
        text-decoration: none;
      }
      &:visited {
        color: $basicColorPrimary;
      }
      &:active {
        color: $basicColorPrimary;
      }
    }
    .current-select {
      border: 1px solid $basicColorPrimary;
      padding: 0.5rem 0;
      background-color: $basicColorPrimary;
      color: $mainColorPrimary !important;
      border-radius: 200px 200px 0px 0px;
      -webkit-border-radius: 200px 200px 0px 0px;
      -moz-border-radius: 200px 200px 0px 0px;
    }
    .option-icon {
      width: 1.5rem;
    }
    .title-option {
      text-align: center;
      font-weight: bold;
      font-size: 0.5rem;
    }
  }
}

//Responsive Tablet Standard >
@media screen and (min-width: $tabletStandard) {
  .navbar-mobile-container {
    display: none;
  }
}
